import React, { useEffect, useState, CSSProperties, useRef } from "react";
import { Backdrop, TextField, Autocomplete, ButtonBase, CardActionArea, Paper, Typography, Box, SvgIcon, Grid, IconButton, Tooltip } from "@mui/material";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { axiosHelperCore } from "utils";
import { useAppSelector } from "hooks";
import { ModalWrapper } from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import SequenceStepList from "./components/SequenceStepList";
import SettingDataTable from "./components/SettingDataTable";
import SavedSequenceStepList from "./components/SavedSequenceStepList";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import ManualSequenceBuilder from "./components/ManualSequenceBuilder";
import { uuidv4 } from "utils/cookie";
import RefreshIcon from '@mui/icons-material/Refresh';
import SavedManualSequenceStepList from "./components/SavedManualSequenceStepList";
import { ToggleButton } from "@evabot/eva-react-core-library/dist/atoms/ToggleButton/ToggleButton";
import MemoRevenoidLoader from "components/RevenoidLoader/RevenoidLoader";
import PipelinerSeqeunceStepBuilder from "./components/PipelinerSeqeunceStepBuilder";
import SavedPipelinerSequenceStepList from "./components/SavedPipelinerSequenceStepList";
import SearchBar from "components/TailwindComponents/SearchBar";

function AutomatedEmailSequenceRightSection2() {
    const [loading, setLoading] = useState(false);
    const [frameworkList, setFrameworkList] = useState([]);
    const [contextList, setContextList] = useState([]);
    const [frameworkListOld, setFrameworkListOld] = useState([]);
    const [contextListOld, setContextListOld] = useState([]);
    const [toneList, setToneList] = useState([]);
    const [wordCountList, setWordCountList] = useState([]);
    const [sequenceMasterList, setSequenceMasterList] = useState([]);
    const [sequenceStepList, setSequenceStepList] = useState([]);
    const [sequenceDropDown, setSequenceDropDown] = useState([]);
    const [hidePopup, setHidePopup] = useState(true);
    const [hidePopupSequenceOption, setHidePopupSequenceOption] = useState(true);
    const [selectedSequence, setSelectedSequence] = useState(null);
    const [sequenceId, setSequenceId] = useState(null);
    const [crmOwnerId, setCrmOwnerId] = useState("");
    const [crmSequenceName, setCrmSequenceName] = useState("");
    const [savedSequenceList, setSavedSequenceList] = useState([]);
    const [savedSequenceOriginalList, setSavedSequenceOriginalList] = useState([]);
    const [autoPersonaSelection, setAutoPersonaSelection] = useState(false);
    const [autoPersonaSelectionSequence, setAutoPersonaSelectionSequence] = useState(false);
    const [selectedManualSequence, setSelectedManualSequence] = useState(null);
    const [savedSequenceStepList, setSavedSequenceStepList] = useState([]);
    const [defaultEmailSetting, setDefaultEmailSetting] = useState({});
    const [defaultLinkedinSetting, setDefaultLinkedinSetting] = useState({});
    const [integrationErrorMsg, setIntegrationErrorMsg] = useState("");
    const [manualSequenceBuilder, setManualSequenceBuilder] = useState(false);
    const [addSequenceName, setAddSequenceName] = useState(false);
    const [addCrmSequence, setAddCrmSequence] = useState(false);
    const [manualSequenceName, setManualSequenceName] = useState("");
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [sequenceMasterListFetched, setSequenceMasterListFetched] = useState(false);
    const [checkIntegrationType, setCheckIntegrationType] = useState("");
    const [manualSavedEmailOptions, setManualSavedEmailOptions] = useState([]);
    const [manualSavedFollowUpOptions, setManualSavedFollowUpOptions] = useState([]);
    const [integrationType, setIntegrationType] = useState(null);
    const [isAutoPersonaSelectionEnabled, setIsAutoPersonaSelectionEnabled] = useState(false);
    const [usedCrmSequenceIds, setUsedCrmSequenceIds] = useState([]);
    const [pipelinerSequenceStepBuilder, setPipelinerSequenceStepBuilder] = useState([]);
    const [pipelinerSequenceBuilder, setpipelinerSequenceBuilder] = useState(false);
    const [pipelinerField, setPipelinerField] = useState([]);
    const searchValue = useRef("");

    const rotatingStyle: CSSProperties = {
        animation: 'rotate 2s linear infinite',
    };

    const loggedInUserDetail = useAppSelector((state) => state.profileState.profileData);

    useEffect(() => {
        if (loggedInUserDetail?.userId) {
            fetchData();
            getAutoPersonaSelectionSettings();
        } else {
            setLoading(true);
        }
    }, [loggedInUserDetail]);

    const fetchData = async () => {
        setLoading(true);
        try {
            await Promise.all([getSaveSequenceList(), getFrameworkContextList(), getFrameworkContextListOld()]);
            // await getSequenceMasterList();
        } catch (error) {
            console.error("Error fetching data", error);
        }
        setLoading(false);
    };

    const getSequenceMasterList = async () => {
        // setLoading(true);
        setSequenceMasterListFetched(false);
        try {
            const result = await axiosHelperCore({
                url: `crm/getSequenceMasterList`,
                method: "POST",
                JSONData: { userId: loggedInUserDetail?.userId, email: "", query: "" }
            });

            if (result?.data?.data) {
                let sequencedropdownlist = result?.data?.data?.map((data) => ({
                    value: data?.crmSequenceId,
                    label: data?.sequenceName,
                    isUsed: usedCrmSequenceIds.includes(data.crmSequenceId.toString()),
                }));


                setSequenceDropDown(sequencedropdownlist);
                setSequenceMasterList(result?.data?.data);
                setSequenceMasterListFetched(true);
                setIntegrationType(result?.data?.integrationType);
            }

            if (!result?.data?.success) {
                setIntegrationErrorMsg(result?.data?.message);
                setSequenceMasterListFetched(true);
            }

        } catch (error) {
            console.error("Error fetching data", error);
        }
        // setLoading(false);
    };

    const getFrameworkContextList = async () => {
        const result = await axiosHelperCore({
            url: `rapport/user/getFrameworkContextListSequence`,
            method: "POST"
        });

        const frameworksFromPrefrenceSettings = result?.data?.data?.prefrenceSettings?.frameworks
            .filter(item => item.type === 'email' || item.type === 'callprep')
            .map(item => ({
                ...item,
                isPublic: true,
            }));

        const otherFrameworks = result?.data?.data?.frameworks.filter(item => (item.type === 'email' || item.type === 'callprep') && item?.isPublic);

        setFrameworkList([{
            "frameworkId": "",
            "name": "None",
            "framework": "",
            "isPublic": true
        },
        ...frameworksFromPrefrenceSettings,
        ...otherFrameworks]);

        setContextList([{
            "contextId": "",
            "name": "None",
            "context": "",
        }, ...result?.data?.data?.contexts?.filter(item => (item.type === 'email' || item.type === 'linkedin' || item.type === 'callprep') && item?.isPublic)]);

        setToneList([...result?.data?.data?.prefrenceSettings.tones]);
        setWordCountList([...result?.data?.data?.prefrenceSettings.wordCount]);
        setDefaultEmailSetting(result?.data?.data?.defaultEmail);
        setDefaultLinkedinSetting(result?.data?.data?.defaultLinkedin);
    };


    const getFrameworkContextListOld = async () => {

        const result = await axiosHelperCore({
            url: `rapport/user/getFrameworkContextList`,
            method: "POST"
        });

        setFrameworkListOld([{
            "frameworkId": "",
            "name": "None",
            "framework": "",
        }, ...result?.data?.data?.prefrenceSettings?.frameworks.filter(item => item.type === 'email' || item.type === 'callprep'), ...result?.data?.data?.frameworks.filter(item => item.type === 'email' || item.type === 'callprep')]);

        setContextListOld([{
            "contextId": "",
            "name": "None",
            "context": "",
        }, ...result?.data?.data?.contexts?.filter(item => item.type === 'email' || item.type === 'linkedin' || item.type === 'callprep')]);
        setToneList([...result?.data?.data?.prefrenceSettings.tones]);
        setWordCountList([...result?.data?.data?.prefrenceSettings.wordCount]);
        setDefaultEmailSetting(result?.data?.data?.defaultEmail);
        setDefaultLinkedinSetting(result?.data?.data?.defaultLinkedin);
    };

    const getSaveSequenceList = async () => {
        setLoading(true);
        try {
            const result = await axiosHelperCore({
                url: `rapport/sequence/getSaveSequenceList`,
                method: "POST",
                JSONData: { userId: loggedInUserDetail?.userId }
            });

            const usedSequenceIdsArray = [
                ...(result?.data?.filter(s => (s.integrationType === "outreach" || s.integrationType === "pipeliner"))?.map((seq) => seq.crmSequenceId) || [])
            ];

            setSequenceDropDown((prevOptions) =>
                prevOptions.map((option) => ({
                    ...option,
                    isUsed: usedSequenceIdsArray.includes(option.value.toString()),
                }))
            );

            setUsedCrmSequenceIds(usedSequenceIdsArray);

            setSavedSequenceList(result?.data);
            setSavedSequenceOriginalList(result?.data);
        } catch (error) {
            console.error("Error fetching data", error);
        }
        setLoading(false);
    }

    const getAutoPersonaSelectionSettings = async () => {
        setLoading(true);
        try {
            const result = await axiosHelperCore({
                url: `rapport/user/getAutoPersonaSelectionSettings`,
                method: "POST",
                JSONData: { organizationId: loggedInUserDetail?.organizationId }
            });

            setAutoPersonaSelection(result?.data?.data ?? false);

        } catch (error) {
            console.error("Error fetching data", error);
        }
        setLoading(false);
    }

    const handleSequenceStepsEdit = async (sequenceId) => {
        setLoading(true);
        try {
            const result = await axiosHelperCore({
                url: `rapport/sequence/getSavedSequenceSteps`,
                method: "POST",
                JSONData: { sequenceId: sequenceId, userId: loggedInUserDetail?.userId }
            });

            const sequenceNameCheck = savedSequenceList.filter(item => item.sequenceId === sequenceId);
            setAutoPersonaSelectionSequence(autoPersonaSelection ? (sequenceNameCheck[0]?.autoPersonaSelection ?? false) : false);
            setCrmSequenceName(sequenceNameCheck[0]?.sequenceName);
            setSelectedManualSequence(sequenceNameCheck?.[0]);
            if (sequenceNameCheck[0]?.integrationType === 'manual' || sequenceNameCheck[0]?.integrationType === 'salesforce') {
                setSequenceId(sequenceId);
                let sequenceStepList = result?.data?.data;

                const emailSteps = sequenceStepList.filter(step => step?.type.includes('email'));
                let emailOpts = emailSteps.map((item, index) => {
                    const typeIndex = item?.type?.split('-');
                    return {
                        value: `email-${typeIndex[1]}`,
                        label: `Email ${typeIndex[1]}`
                    }
                });

                const extraEmailOption = {
                    value: `email-${emailSteps.length + 1}`,
                    label: `Email ${emailSteps.length + 1}`
                };
                emailOpts.push(extraEmailOption);

                let followUpOpts = emailSteps.map((item, index) => {
                    const typeIndex = item?.type?.split('-');
                    return {
                        value: `followUp-${typeIndex[1]}`,
                        label: `FollowUp Email ${typeIndex[1]}`
                    }
                });

                setManualSavedEmailOptions(emailOpts);
                setManualSavedFollowUpOptions(followUpOpts);
                // Update the array
                sequenceStepList = sequenceStepList.map((step) => {
                    
                    if (step.type.includes('email') || step.type.includes('followUp')) {
                        step.customFields = {
                            ...step.customFields,
                            emailSubject: step?.field1,
                            emailBody: step?.field2,
                        };
                    } else if (step.type.includes('linkedinInMail')) {
                        step.customFields = {
                            ...step.customFields,
                            linkedinSubject: step?.field1,
                            linkedinBody: step?.field2,
                        };
                    } else if (step.type.includes('linkedinDirectMessage') || step.type.includes('linkedinConnectionRequest')) {
                        step.customFields = {
                            ...step.customFields,
                            linkedinBody: step?.field2,
                        };
                    }
                    
                    else if (step.type.includes('call')) {
                        step.customFields = {
                            ...step.customFields,
                            callprepBody: step?.field2,
                        };
                    }

                    // Remove old fields
                    delete step?.field1;
                    delete step?.field2;
                    delete step?.framework;
                    delete step?.tone;
                    delete step?.context;

                    step = {
                        ...step,
                        framework: step?.frameworkId,
                        tone: step?.toneId,
                        wordCount: step?.wordId,
                        context: step?.contextId,
                    };

                    return step;
                });
                setSavedSequenceStepList(sequenceStepList);
            }else if(sequenceNameCheck[0]?.integrationType === 'pipeliner'){
                await getPipelinerField();
                setSequenceId(sequenceId);
                let sequenceStepList = result?.data?.data;

                const emailSteps = sequenceStepList.filter(step => step?.type.includes('email'));
                let emailOpts = emailSteps.map((item, index) => {
                    const typeIndex = item?.type?.split('-');
                    return {
                        value: `email-${typeIndex[1]}`,
                        label: `Email ${typeIndex[1]}`
                    }
                });


                setManualSavedEmailOptions(emailOpts);
                // Update the array
                sequenceStepList = sequenceStepList.map((step) => {
                    
                    if (step.type.includes('email')) {
                        step.customFields = {
                            ...step.customFields,
                            emailSubject: step?.field1,
                            emailBody: step?.field2,
                        };
                    }

                    // Remove old fields
                    delete step?.field1;
                    delete step?.field2;
                    delete step?.framework;
                    delete step?.tone;
                    delete step?.context;

                    step = {
                        ...step,
                        framework: step?.frameworkId,
                        tone: step?.toneId,
                        wordCount: step?.wordId,
                        context: step?.contextId,
                    };

                    return step;
                });
                setSavedSequenceStepList(sequenceStepList);
            }
            else {
                setSavedSequenceStepList(result?.data?.data);
            }
            setCheckIntegrationType(sequenceNameCheck[0]?.integrationType);
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    }

    const handleAddSequenceClick = () => {
        setHidePopupSequenceOption(false);
        // if (integrationType === "salesforce") {
        //     setAddSequenceName(true);
        // }
    };

    const handleConfigureSteps = async () => {
        setLoading(true);
        try {
            if(integrationType === "pipeliner"){
                setpipelinerSequenceBuilder(true);
                await getPipelinerField();
                const getSequenceSteps = sequenceMasterList.find(list => list.crmSequenceId === selectedSequence?.value);
                if (getSequenceSteps) {
                    const buildSequenceSteps = getSequenceSteps.steps.filter(item => item?.email).map((item, index) => {
                        return {
                            stepName: `Step ${index + 1} (Email ${index + 1})`,
                            stepType: `email-${index + 1}`,
                            customFields: {},
                            framework: '',
                            tone: '',
                            wordCount: '',
                            context: '',
                            activated: true,
                            crmStepId : item?.id
                        };
                    });
                    
                    setPipelinerSequenceStepBuilder(buildSequenceSteps);
                    setCrmOwnerId(getSequenceSteps.ownerId);
                    setCrmSequenceName(getSequenceSteps.sequenceName);
                    setSequenceId(getSequenceSteps?.crmSequenceId);
                }
            }else{
                const result = await axiosHelperCore({
                    url: `crm/getSequenceSteps`,
                    method: "POST",
                    JSONData: { userId: loggedInUserDetail?.userId, sequenceId: selectedSequence?.value }
                });

                console.log(result?.data);
                setSequenceStepList(result?.data?.data?.steps);
                setSequenceId(result?.data?.data?.sequenceId);
                const listData = sequenceMasterList.find(list => result?.data?.data?.sequenceId === list.crmSequenceId);
                setCrmOwnerId(listData?.ownerId);
                setCrmSequenceName(listData?.sequenceName);
            }
        } catch (error) {
            console.error("Error fetching data", error);
        }
        setLoading(false);
        setHidePopupSequenceOption(true);
    };

    const handleSaveSequence = async (data) => {
        setLoading(true);
        try {

            const formatSequenceData = {
                userId: loggedInUserDetail?.userId,
                crmSequenceId: sequenceId,
                crmOwnerId: crmOwnerId,
                sequenceName: crmSequenceName,
                integrationType: "outreach",
                numberOfSteps: data?.length,
                autoPersonaSelection: isAutoPersonaSelectionEnabled
            }

            const result = await axiosHelperCore({
                url: `rapport/sequence/saveSequence`,
                method: "POST",
                JSONData: { sequenceData: formatSequenceData, sequenceStepData: data }
            });

            if (result?.data?.success) {
                handleBack();
                await getSaveSequenceList();
            }
        } catch (error) {
            console.log("Error fetching data", error);
        }
        setLoading(false);
    }

    const handleManualSaveSequence = async (data, sequenceName) => {
        setLoading(true);
        try {

            const formatSequenceData = {
                userId: loggedInUserDetail?.userId,
                crmSequenceId: uuidv4(),
                crmOwnerId: uuidv4(),
                sequenceName: sequenceName,
                integrationType: addCrmSequence ? "salesforce" : "manual",
                numberOfSteps: data?.length,
                autoPersonaSelection: isAutoPersonaSelectionEnabled
            }

            console.log({ sequenceData: formatSequenceData, sequenceStepData: data });

            const result = await axiosHelperCore({
                url: `rapport/sequence/saveSequence`,
                method: "POST",
                JSONData: { sequenceData: formatSequenceData, sequenceStepData: data }
            });

            if (result?.data?.success) {
                handleBack();
                await getSaveSequenceList();
            }
        } catch (error) {
            console.error("Error fetching data", error);
        }
        setLoading(false);
    }

    const handlePipelinerSaveSequence = async (data, sequenceName) => {
        setLoading(true);
        try {

            const formatSequenceData = {
                userId: loggedInUserDetail?.userId,
                crmSequenceId: sequenceId,
                crmOwnerId: crmOwnerId,
                sequenceName: sequenceName,
                integrationType: "pipeliner",
                numberOfSteps: data?.length,
                autoPersonaSelection: isAutoPersonaSelectionEnabled
            }

            console.log({ sequenceData: formatSequenceData, sequenceStepData: data });

            const result = await axiosHelperCore({
                url: `rapport/sequence/saveSequence`,
                method: "POST",
                JSONData: { sequenceData: formatSequenceData, sequenceStepData: data }
            });

            if (result?.data?.success) {
                handleBack();
                await getSaveSequenceList();
            }
        } catch (error) {
            console.error("Error fetching data", error);
        }
        setLoading(false);
    }

    const handleUpdateSequenceSteps = async (data) => {
        setLoading(true);
        try {

            const result = await axiosHelperCore({
                url: `rapport/sequence/updateSavedSequence`,
                method: "POST",
                JSONData: { sequenceStepData: data, userId: loggedInUserDetail?.userId }
            });

            if (result?.data?.data?.success) {
                handleBack();
                await getSaveSequenceList();
            }


        } catch (error) {
            console.error("Error fetching data", error);
        }
        setLoading(false);
    }

    const getPipelinerField = async () => {
        setLoading(true);
        try {

            const result = await axiosHelperCore({
                url: `pipeliner/getFields`,
                method: "POST",
                JSONData: { entityName: "Contact" }
            });

            if (result?.data?.success) {
                setPipelinerField(result?.data?.fields);
            }


        } catch (error) {
            console.error("Error fetching data", error);
        }
        setLoading(false);
    }

    const handleRefreshSequenceClick = async () => {
        setIsRefreshing(true);
        try {
            await getSequenceMasterList();
        } catch (error) {
            console.error("Error fetching data", error);
        }
        setIsRefreshing(false);
    };

    const handleBack = () => {
        setSequenceStepList([]);
        setSavedSequenceStepList([]);
        setManualSequenceBuilder(false);
        setAddSequenceName(false);
        setAddCrmSequence(false);
        setCheckIntegrationType("");
        setCrmSequenceName("");
        setpipelinerSequenceBuilder(false);
        setPipelinerSequenceStepBuilder([]);
    };

    const handleSearchChange = (e) => {
        searchValue.current = e?.target?.value?.toLowerCase();
        seachDataPipe();
    };

    const seachDataPipe = async () => {
        let searchContext = searchValue.current;
        let filteredData = savedSequenceOriginalList;

        if (searchContext.trim() != "") {
            filteredData = filteredData.filter(m => {
                const titleIncludes = m?.sequenceName?.toLowerCase().includes(searchContext);
                const tagsIncludes = m?.integrationType?.toLowerCase().includes(searchContext);
                const updatedBy = m?.activatedBy?.toLowerCase().includes(searchContext);
                return titleIncludes || tagsIncludes || updatedBy;
            });
            setSavedSequenceList(filteredData);
        }
        else {
            setSavedSequenceList(filteredData);
        }
    };

    useEffect(() => {
        if (savedSequenceList.length > 0 && savedSequenceList && sequenceMasterList && sequenceMasterList.length > 0) {
            const filteredSequences = sequenceMasterList?.filter((data) => {
                return !savedSequenceList.some(
                    (savedSequence) => parseInt(savedSequence.crmSequenceId) === data.crmSequenceId
                );
            });

            let sequencedropdownlist = filteredSequences?.map((data) => ({
                value: data?.crmSequenceId,
                label: data?.sequenceName,
                isUsed: usedCrmSequenceIds.includes(data.crmSequenceId.toString()),
            }));
            setSequenceDropDown(sequencedropdownlist);
        }
    }, [savedSequenceList])

    useEffect(() => {
        //if (usedCrmSequenceIds.length > 0) {
        if (loggedInUserDetail?.userId){
            getSequenceMasterList();
        }
        //}
    }, [usedCrmSequenceIds]);

    return (
        <div className="overflow-y-scroll">
            <div className="py-3">
                {loading && (
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                        open={true}
                    >
                        <MemoRevenoidLoader />
                    </Backdrop>
                )}
                {!sequenceStepList.length && !savedSequenceStepList.length && !manualSequenceBuilder && !pipelinerSequenceBuilder && (
                    <div>
                        <div style={{display : "flex", justifyContent : "space-between"}}>
                            <div className="flex col gap-4 items-center justify-end mt-2 mb-5 px-10">
                                <SearchBar placeholder="Search via name/type/created by" onChange={handleSearchChange} className="h-2"/>
                            </div>
                            <div className="flex col gap-4 items-center justify-end mt-2 mb-5 px-10">
                                {/* <div>
                                        <Button iconName="add" onClick={handleRefreshSequenceClick} variant="secondary">
                                            Refresh Sequence
                                        </Button>
                                    </div> */}
                                <div>
                                    <Button fontSize={"14px"} padding={"10px 10px"} size="xs" iconName="add" onClick={handleAddSequenceClick} variant="secondary" disabled={integrationErrorMsg.length > 0 && sequenceMasterListFetched && true}>
                                        Add Sequence
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div>
                            {!integrationErrorMsg && savedSequenceList.length > 0 && !manualSequenceBuilder && !pipelinerSequenceBuilder && <SettingDataTable sequenceList={savedSequenceList} getSavedSequenceList={getSaveSequenceList} handleSequenceEdit={handleSequenceStepsEdit} autoPersonaSelection={autoPersonaSelection} />}
                            {integrationErrorMsg && <div style={{ height: "100%", width: "90%", justifyContent: "center", fontWeight: "500", fontSize: "20px", color: "#050505", textAlign: "center", background: "#fff", padding: "50px", marginLeft: "75px" }}>{integrationErrorMsg}</div>}
                            {!integrationErrorMsg && !loading && (savedSequenceList.length === 0) && pipelinerSequenceStepBuilder.length === 0 && <div style={{ height: "100%", width: "92%", justifyContent: "center", fontWeight: "500", fontSize: "20px", color: "#050505", textAlign: "center", background: "#fff", padding: "50px", marginLeft: "50px", marginTop: "20px", borderRadius: "8px" }}>No Sequence Found</div>}
                        </div>
                    </div>
                )}
            </div>

            <ModalWrapper
                width="944px"
                height={"auto"}
                hideModal={hidePopupSequenceOption}
                setHideModal={() => {
                    setHidePopupSequenceOption(true);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="4% 2%"
            >
                <div className="modal-content" style={{ position: 'relative' }}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="944"
                        height="503"
                        viewBox="0 0 944 503"
                        fill="none"
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 0,
                            pointerEvents: 'none'
                        }}
                    >
                        <path
                            opacity="0.64"
                            d="M3.86092 -680.695C-19.7369 -664.831 -39.0373 -640.424 -42.5876 -612.139C-46.3898 -581.813 -31.2235 -551.217 -8.29008 -531.186C57.7936 -473.467 165.969 -492.254 243.646 -455.446C299.705 -428.886 359.107 -373.111 346.54 -305.295C341.165 -276.341 321.414 -251.595 297.528 -234.452C228.287 -184.778 138.097 -183.929 61.0717 -153.726C-19.3119 -122.209 -93.9366 -92.4428 -137.191 -12.9336C-181.334 68.2235 -192.137 167.46 -165.029 255.776C-152.511 296.583 -131.653 335.826 -99.2442 363.452C21.4095 466.249 199.412 380.843 322.543 329.022C455.724 272.967 606.053 192.188 754.542 230.734C831.584 250.756 900.377 297.923 950.291 359.737C997.405 418.08 1050.73 512.563 1018.21 588.818"
                            stroke="#8D68F6"
                            stroke-opacity="0.964706"
                            stroke-width="47"
                            stroke-linecap="round"
                        />
                    </svg>

                    <div style={{ position: 'relative', zIndex: 1 }}>
                        <div style={{ textAlign: "center" }}>
                            <Grid item className="MuiGrid-item">
                                <Typography variant="h5" className="MuiTypography-root MuiTypography-h5" color={"#16124C"} fontSize={"24px"} fontWeight={"600"} gutterBottom>
                                    {addCrmSequence ? "Create a CRM Based Sequence" : (addSequenceName ? "Create a Manual Sequence" : "Create a Sequence")}
                                </Typography>
                            </Grid>
                            <Typography variant="body2" className="MuiTypography-root MuiTypography-body1" color={"#16124C"} fontSize={"16px"} fontWeight={"400"} gutterBottom>
                                Sequences are a series of manual or automated<br /> touchpoints and activities spread out over time.
                            </Typography>
                        </div>

                        {!addCrmSequence && hidePopup && !addSequenceName && (
                            <Flex justifyContent={"center"} gap={"10px"} mt={"25px"}>
                                {integrationType != "pipeliner" && (<Paper elevation={4}
                                    style={{
                                        backgroundColor: 'rgb(255 255 255)',
                                        opacity: '0.90',
                                        borderRadius: '9px',
                                        transition: 'background-color 0.3s ease',
                                    }}
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'rgb(232 233 253) !important',
                                        },
                                    }}
                                >
                                    <ButtonBase component={CardActionArea} onClick={() => { setAddSequenceName(true); }} type="button">
                                        <Box p={5} display="flex" flexDirection="column" alignItems="center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M27.0031 16.168C30.7893 15.4639 33.6559 12.1432 33.6559 8.15287C33.6559 3.65017 30.0058 0 25.503 0C21.0003 0 17.3502 3.65017 17.3502 8.15287C17.3502 12.1432 20.2168 15.4639 24.0031 16.1681V23.788H13.326C9.64067 23.788 6.65287 26.7758 6.65287 30.4612V30.5207C2.86665 31.2248 0 34.5456 0 38.5358C0 43.0386 3.65017 46.6887 8.15287 46.6887C12.6556 46.6887 16.3057 43.0386 16.3057 38.5358C16.3057 34.5456 13.4391 31.2248 9.65287 30.5207V30.4612C9.65287 28.4327 11.2975 26.788 13.326 26.788H24.0031V34.8319C20.2168 35.5361 17.3502 38.8568 17.3502 42.8471C17.3502 47.3498 21.0003 51 25.503 51C30.0058 51 33.6559 47.3498 33.6559 42.8471C33.6559 38.8568 30.7893 35.5361 27.0031 34.832V26.788H37.674C39.7025 26.788 41.3471 28.4327 41.3471 30.4612V30.5207C37.5609 31.2248 34.6943 34.5456 34.6943 38.5358C34.6943 43.0386 38.3444 46.6887 42.8471 46.6887C47.3498 46.6887 51 43.0386 51 38.5358C51 34.5455 48.1333 31.2248 44.3471 30.5207V30.4612C44.3471 26.7758 41.3593 23.788 37.674 23.788H27.0031V16.168Z" fill="url(#paint0_linear_22011_3878)" />
                                                <g filter="url(#filter0_dii_22011_3878)">
                                                    <path d="M25.4968 4.24219C23.3403 4.24219 21.5894 5.99318 21.5894 8.14967C21.5894 10.3062 23.3403 12.0571 25.4968 12.0571C27.6533 12.0571 29.4043 10.3062 29.4043 8.14967C29.4043 5.99318 27.6533 4.24219 25.4968 4.24219ZM25.4968 9.55661C24.7227 9.55661 24.0899 8.92994 24.0899 8.14967C24.0899 7.3694 24.7166 6.74273 25.4968 6.74273C26.2771 6.74273 26.9038 7.3694 26.9038 8.14967C26.9038 8.92994 26.2771 9.55661 25.4968 9.55661Z" fill="white" fill-opacity="0.5" shape-rendering="crispEdges" />
                                                </g>
                                                <g filter="url(#filter1_dii_22011_3878)">
                                                    <path d="M11.2555 40.5355C11.4705 40.9041 11.2002 41.3649 10.7763 41.3649H5.5356C5.11168 41.3649 4.84749 40.9041 5.05638 40.5355L7.6798 35.9952C7.89484 35.6266 8.42321 35.6266 8.6321 35.9952L11.2555 40.5355Z" fill="white" fill-opacity="0.5" shape-rendering="crispEdges" />
                                                </g>
                                                <g filter="url(#filter2_dii_22011_3878)">
                                                    <path d="M22.6293 42.4115L24.7359 40.3041C25.1572 39.8826 25.835 39.8825 26.2564 40.3038L28.3638 42.4105C28.7853 42.8318 28.7854 43.5095 28.3641 43.931L26.2574 46.0384C25.8361 46.4599 25.154 46.4556 24.7369 46.0386L22.6295 43.932C22.2081 43.5107 22.2079 42.833 22.6293 42.4115Z" fill="white" fill-opacity="0.5" shape-rendering="crispEdges" />
                                                </g>
                                                <g filter="url(#filter3_dii_22011_3878)">
                                                    <path d="M46.5518 39.2396C46.5518 39.4547 46.3737 39.6328 46.1586 39.6328H44.8807C44.5797 39.6328 44.3892 39.9646 44.5367 40.2227L45.1756 41.3285C45.2862 41.519 45.2186 41.7586 45.0282 41.8692L44.3646 42.2501C44.1742 42.3607 43.9346 42.2931 43.824 42.1027L43.185 40.9968C43.0314 40.7326 42.6505 40.7326 42.5031 40.9968L41.8641 42.1027C41.7535 42.2931 41.5139 42.3546 41.3234 42.2501L40.6599 41.8692C40.4694 41.7586 40.408 41.519 40.5125 41.3285L41.1514 40.2227C41.305 39.9585 41.1145 39.6328 40.8074 39.6328H39.5294C39.3083 39.6328 39.1362 39.4547 39.1362 39.2396V38.4778C39.1362 38.2628 39.3144 38.0846 39.5294 38.0846H40.8074C41.1145 38.0846 41.2989 37.7528 41.1514 37.4948L40.5125 36.3889C40.4019 36.1984 40.4694 35.9588 40.6599 35.8482L41.3234 35.4673C41.5139 35.3567 41.7535 35.4243 41.8641 35.6148L42.5031 36.7207C42.6566 36.9849 43.0314 36.9849 43.185 36.7207L43.824 35.6148C43.9346 35.4243 44.1742 35.3629 44.3646 35.4673L45.0282 35.8482C45.2186 35.9588 45.2801 36.1984 45.1756 36.3889L44.5367 37.4948C44.3831 37.759 44.5735 38.0846 44.8807 38.0846H46.1586C46.3737 38.0846 46.5518 38.2628 46.5518 38.4778V39.2396Z" fill="white" fill-opacity="0.5" shape-rendering="crispEdges" />
                                                </g>
                                                <defs>
                                                    <filter id="filter0_dii_22011_3878" x="19.8394" y="2.49219" width="12.3149" height="12.3125" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset dx="0.5" dy="0.5" />
                                                        <feGaussianBlur stdDeviation="1.125" />
                                                        <feComposite in2="hardAlpha" operator="out" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 0.0862745 0 0 0 0 0.0705882 0 0 0 0 0.298039 0 0 0 0.25 0" />
                                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_22011_3878" />
                                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_22011_3878" result="shape" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset dy="1.5" />
                                                        <feGaussianBlur stdDeviation="2" />
                                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                                                        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_22011_3878" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset />
                                                        <feGaussianBlur stdDeviation="1.25" />
                                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
                                                        <feBlend mode="normal" in2="effect2_innerShadow_22011_3878" result="effect3_innerShadow_22011_3878" />
                                                    </filter>
                                                    <filter id="filter1_dii_22011_3878" x="3.2334" y="33.9688" width="10.8477" height="10.1484" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset dx="0.5" dy="0.5" />
                                                        <feGaussianBlur stdDeviation="1.125" />
                                                        <feComposite in2="hardAlpha" operator="out" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 0.0862745 0 0 0 0 0.0705882 0 0 0 0 0.298039 0 0 0 0.25 0" />
                                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_22011_3878" />
                                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_22011_3878" result="shape" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset dy="1.5" />
                                                        <feGaussianBlur stdDeviation="2" />
                                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                                                        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_22011_3878" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset />
                                                        <feGaussianBlur stdDeviation="1.25" />
                                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
                                                        <feBlend mode="normal" in2="effect2_innerShadow_22011_3878" result="effect3_innerShadow_22011_3878" />
                                                    </filter>
                                                    <filter id="filter2_dii_22011_3878" x="20.5635" y="38.2344" width="10.8667" height="10.8672" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset dx="0.5" dy="0.5" />
                                                        <feGaussianBlur stdDeviation="1.125" />
                                                        <feComposite in2="hardAlpha" operator="out" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 0.0862745 0 0 0 0 0.0705882 0 0 0 0 0.298039 0 0 0 0.25 0" />
                                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_22011_3878" />
                                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_22011_3878" result="shape" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset dy="1.5" />
                                                        <feGaussianBlur stdDeviation="2" />
                                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                                                        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_22011_3878" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset />
                                                        <feGaussianBlur stdDeviation="1.25" />
                                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
                                                        <feBlend mode="normal" in2="effect2_innerShadow_22011_3878" result="effect3_innerShadow_22011_3878" />
                                                    </filter>
                                                    <filter id="filter3_dii_22011_3878" x="37.3862" y="33.6641" width="11.9155" height="11.3906" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset dx="0.5" dy="0.5" />
                                                        <feGaussianBlur stdDeviation="1.125" />
                                                        <feComposite in2="hardAlpha" operator="out" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 0.0862745 0 0 0 0 0.0705882 0 0 0 0 0.298039 0 0 0 0.25 0" />
                                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_22011_3878" />
                                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_22011_3878" result="shape" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset dy="1.5" />
                                                        <feGaussianBlur stdDeviation="2" />
                                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                                                        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_22011_3878" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset />
                                                        <feGaussianBlur stdDeviation="1.25" />
                                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
                                                        <feBlend mode="normal" in2="effect2_innerShadow_22011_3878" result="effect3_innerShadow_22011_3878" />
                                                    </filter>
                                                    <linearGradient id="paint0_linear_22011_3878" x1="25.5" y1="0" x2="25.5" y2="51" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#0043C7" />
                                                        <stop offset="1" stop-color="#8D68F6" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                            <Typography variant="button" gutterBottom color={"#16124C"} fontSize={"18px"} fontWeight={"600"}>
                                                Manual Sequence
                                            </Typography>
                                            <Typography variant="caption" gutterBottom color={"#16124C"} fontSize={"16px"} fontWeight={"400"}>
                                                Start from scratch to create a fully <br />customized sequence.
                                            </Typography>
                                        </Box>
                                    </ButtonBase>
                                </Paper>)}
                                <Paper elevation={4}
                                    style={{
                                        backgroundColor: 'rgb(255 255 255)',
                                        opacity: '0.90',
                                        borderRadius: '9px',
                                        transition: 'background-color 0.3s ease',
                                    }}
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'rgb(232 233 253) !important',
                                        },
                                    }}
                                >
                                    <ButtonBase component={CardActionArea} onClick={() => { setAddCrmSequence(true); }} type="button">
                                        <Box p={5} display="flex" flexDirection="column" alignItems="center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3994 6.06757C10.3994 3.26883 12.6682 1 15.467 1H47.378C50.1767 1 52.4455 3.26883 52.4455 6.06757C52.4455 8.86631 50.1767 11.1351 47.378 11.1351H15.467C12.6682 11.1351 10.3994 8.86631 10.3994 6.06757ZM10.3995 17.5541C10.3995 14.7553 12.6683 12.4865 15.4671 12.4865H47.3781C50.1768 12.4865 52.4457 14.7553 52.4457 17.5541C52.4457 20.3528 50.1768 22.6216 47.3781 22.6216H15.4671C12.6683 22.6216 10.3995 20.3528 10.3995 17.5541ZM15.4671 24.6486C12.6683 24.6486 10.3995 26.9175 10.3995 29.7162C10.3995 32.515 12.6683 34.7838 15.4671 34.7838H47.3781C50.1768 34.7838 52.4457 32.515 52.4457 29.7162C52.4457 26.9175 50.1768 24.6486 47.3781 24.6486H15.4671Z" fill="url(#paint0_linear_22011_3889)" />
                                                <g filter="url(#filter0_bdii_22011_3889)">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9618 2.85076C17.1726 2.18517 18.0532 2.18516 18.2639 2.85075L18.8238 4.61921H20.605C21.2776 4.61921 21.5499 5.54527 20.9982 5.95659L19.5817 7.01274L20.1284 8.73943C20.341 9.41112 19.6282 9.9833 19.0841 9.57763L17.6129 8.48067L16.1416 9.57763C15.5975 9.9833 14.8847 9.41112 15.0974 8.73943L15.6441 7.01274L14.2276 5.95659C13.6759 5.54527 13.9481 4.61921 14.6208 4.61921H16.4019L16.9618 2.85076ZM18.1728 4.85476L17.6129 3.08631L17.053 4.85476C16.9585 5.15315 16.6966 5.35395 16.4019 5.35395H14.6208L16.0373 6.4101C16.2881 6.59709 16.3931 6.93869 16.2951 7.2483L15.7484 8.97499L17.2197 7.87803C17.4559 7.70189 17.7698 7.70189 18.0061 7.87803L19.4773 8.97499L18.9306 7.2483C18.8326 6.93869 18.9377 6.59709 19.1885 6.4101L20.605 5.35395H18.8238C18.5292 5.35395 18.2673 5.15315 18.1728 4.85476Z" fill="white" fill-opacity="0.5" shape-rendering="crispEdges" />
                                                    <path d="M17.6129 3.08631L18.1728 4.85476C18.2673 5.15315 18.5292 5.35395 18.8238 5.35395H20.605L19.1885 6.4101C18.9377 6.59709 18.8326 6.93869 18.9306 7.2483L19.4773 8.97499L18.0061 7.87803C17.7698 7.70189 17.4559 7.70189 17.2197 7.87803L15.7484 8.97499L16.2951 7.2483C16.3931 6.93869 16.2881 6.59709 16.0373 6.4101L14.6208 5.35395H16.4019C16.6966 5.35395 16.9585 5.15315 17.053 4.85476L17.6129 3.08631Z" fill="white" fill-opacity="0.5" shape-rendering="crispEdges" />
                                                </g>
                                                <g filter="url(#filter1_dii_22011_3889)">
                                                    <path d="M14.8635 16.807L17.0616 14.6266C17.5013 14.1905 18.2084 14.1904 18.6482 14.6263L20.8472 16.806C21.287 17.2419 21.2871 17.9431 20.8474 18.3792L18.6493 20.5596C18.2096 20.9956 17.4979 20.9913 17.0627 20.5598L14.8637 18.3802C14.424 17.9443 14.4238 17.2431 14.8635 16.807Z" fill="white" fill-opacity="0.5" shape-rendering="crispEdges" />
                                                </g>
                                                <g filter="url(#filter2_dii_22011_3889)">
                                                    <path d="M21.2828 31.6304C21.5152 32.0254 21.223 32.5192 20.7649 32.5192H15.1012C14.643 32.5192 14.3575 32.0254 14.5833 31.6304L17.4184 26.765C17.6508 26.37 18.2219 26.37 18.4476 26.765L21.2828 31.6304Z" fill="white" fill-opacity="0.5" shape-rendering="crispEdges" />
                                                </g>
                                                <g filter="url(#filter3_bd_22011_3889)">
                                                    <path d="M30.7847 42.1237L30.7842 42.1237L30.7479 42.1236L30.7475 42.1236L28.4333 42.1236C27.6075 42.1236 26.9398 41.4539 26.9398 40.6263C26.9398 39.8016 27.6111 39.129 28.4352 39.129L33.6272 39.129C35.6702 39.129 37.3316 37.4775 37.3316 35.4395C37.3316 33.3986 35.6846 31.75 33.6448 31.75L22.6687 31.75C21.0842 31.75 18.8274 32.2813 17.413 32.9873L7.97765 37.697L7.97077 37.7004L7.97073 37.7004C7.86123 37.752 7.75713 37.8136 7.65954 37.884L7.65854 37.8847C7.4974 38.0002 7.3582 38.1379 7.24161 38.2918L7.24145 38.292C6.99203 38.6206 6.84459 39.0296 6.84459 39.4736L6.84459 48.6952C6.84459 49.7773 7.7234 50.6554 8.80885 50.6554L36.5246 50.6554C38.1764 50.6554 40.4465 49.9886 41.8433 49.0931C41.8433 49.0931 41.8434 49.0931 41.8434 49.093L54.3128 41.0908L54.3128 41.0908C56.2027 39.8781 56.7251 37.3759 55.4759 35.5049L30.7847 42.1237ZM30.7847 42.1237L36.5239 42.1237M30.7847 42.1237L36.5239 42.1237M49.8907 34.2785L49.8907 34.2786L37.6313 41.8094L49.8907 34.2785ZM49.8907 34.2785C51.771 33.123 54.2519 33.6722 55.4758 35.5048L49.8907 34.2785ZM36.5239 42.1237C36.6799 42.1237 36.9064 42.0733 37.0917 42.0207M36.5239 42.1237L37.0917 42.0207M37.0917 42.0207C37.2772 41.9681 37.4969 41.8921 37.6311 41.8096L37.0917 42.0207Z" fill="#8667F4" fill-opacity="0.7" stroke="url(#paint1_linear_22011_3889)" stroke-width="0.689189" />
                                                </g>
                                                <defs>
                                                    <filter id="filter0_bdii_22011_3889" x="10.9131" y="-0.667465" width="13.3994" height="13.3974" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.50951" />
                                                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_22011_3889" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset dx="0.367736" dy="0.367736" />
                                                        <feGaussianBlur stdDeviation="0.919341" />
                                                        <feComposite in2="hardAlpha" operator="out" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 0.552941 0 0 0 0 0.407843 0 0 0 0 0.964706 0 0 0 0.5 0" />
                                                        <feBlend mode="multiply" in2="effect1_backgroundBlur_22011_3889" result="effect2_dropShadow_22011_3889" />
                                                        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_22011_3889" result="shape" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset />
                                                        <feGaussianBlur stdDeviation="0.505637" />
                                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                                                        <feBlend mode="normal" in2="shape" result="effect3_innerShadow_22011_3889" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset dy="0.597571" />
                                                        <feGaussianBlur stdDeviation="0.620555" />
                                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                                                        <feBlend mode="normal" in2="effect3_innerShadow_22011_3889" result="effect4_innerShadow_22011_3889" />
                                                    </filter>
                                                    <filter id="filter1_dii_22011_3889" x="13.3794" y="13.1426" width="9.6117" height="9.55408" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset dx="0.329794" dy="0.329794" />
                                                        <feGaussianBlur stdDeviation="0.742036" />
                                                        <feComposite in2="hardAlpha" operator="out" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 0.0862745 0 0 0 0 0.0705882 0 0 0 0 0.298039 0 0 0 0.25 0" />
                                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_22011_3889" />
                                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_22011_3889" result="shape" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset dy="0.989382" />
                                                        <feGaussianBlur stdDeviation="1.31918" />
                                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                                                        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_22011_3889" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset />
                                                        <feGaussianBlur stdDeviation="0.824485" />
                                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
                                                        <feBlend mode="normal" in2="effect2_innerShadow_22011_3889" result="effect3_innerShadow_22011_3889" />
                                                    </filter>
                                                    <filter id="filter2_dii_22011_3889" x="13.3089" y="25.2732" width="9.93402" height="9.12103" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset dx="0.341572" dy="0.341572" />
                                                        <feGaussianBlur stdDeviation="0.768538" />
                                                        <feComposite in2="hardAlpha" operator="out" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 0.0862745 0 0 0 0 0.0705882 0 0 0 0 0.298039 0 0 0 0.25 0" />
                                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_22011_3889" />
                                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_22011_3889" result="shape" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset dy="1.02472" />
                                                        <feGaussianBlur stdDeviation="1.36629" />
                                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                                                        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_22011_3889" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset />
                                                        <feGaussianBlur stdDeviation="0.853931" />
                                                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
                                                        <feBlend mode="normal" in2="effect2_innerShadow_22011_3889" result="effect3_innerShadow_22011_3889" />
                                                    </filter>
                                                    <filter id="filter3_bd_22011_3889" x="0.986486" y="25.2035" width="60.3378" height="30.6208" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.41216" />
                                                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_22011_3889" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                        <feOffset dx="-0.689189" dy="-1.37838" />
                                                        <feGaussianBlur stdDeviation="2.41216" />
                                                        <feComposite in2="hardAlpha" operator="out" />
                                                        <feColorMatrix type="matrix" values="0 0 0 0 0.0862745 0 0 0 0 0.0705882 0 0 0 0 0.298039 0 0 0 0.4 0" />
                                                        <feBlend mode="normal" in2="effect1_backgroundBlur_22011_3889" result="effect2_dropShadow_22011_3889" />
                                                        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_22011_3889" result="shape" />
                                                    </filter>
                                                    <linearGradient id="paint0_linear_22011_3889" x1="32.9705" y1="-8.80392" x2="39.343" y2="58.3529" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="#8D68F6" />
                                                        <stop offset="1" stop-color="#0043C7" />
                                                    </linearGradient>
                                                    <linearGradient id="paint1_linear_22011_3889" x1="52.7838" y1="5.39189" x2="8.18919" y2="54.7162" gradientUnits="userSpaceOnUse">
                                                        <stop stop-color="white" />
                                                        <stop offset="1" stop-color="white" stop-opacity="0" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                            <Typography variant="button" gutterBottom color={"#16124C"} fontSize={"18px"} fontWeight={"600"}>
                                                CRM Sequence
                                            </Typography>
                                            <Typography variant="caption" gutterBottom color={"#16124C"} fontSize={"16px"} fontWeight={"400"}>
                                                Speed up your workflow with one of <br />Outreach's suggested sequences.
                                            </Typography>
                                        </Box>
                                    </ButtonBase>
                                </Paper>
                            </Flex>
                        )}

                        {addCrmSequence && integrationType != "salesforce" && integrationType != "pipeliner" && (
                            <div style={{ marginTop: '10px' }}>
                                <div style={{ gap: '10px', display: 'flex' }}>
                                    <Autocomplete
                                        className="w-[300px] mt-6 text-[12px]"
                                        options={sequenceDropDown}
                                        fullWidth
                                        disabled={isRefreshing}
                                        getOptionLabel={(option) =>
                                            `${option.label}${option.isUsed ? " (Already Used)" : ""}`
                                        }
                                        onChange={(event, value) => setSelectedSequence(value)}
                                        renderInput={(params) => <TextField {...params} label="Search Sequence" />}
                                        renderOption={(props, option) => (
                                            <li {...props} key={option.value} style={{ opacity: option.isUsed ? 0.5 : 1, pointerEvents: option.isUsed ? 'none' : 'auto' }}>
                                                {option.label}
                                                {option.isUsed && (
                                                    <Typography variant="caption" sx={{ color: "red", ml: 1 }}>
                                                        (Already Used)
                                                    </Typography>
                                                )}
                                            </li>
                                        )}
                                    />
                                    <Tooltip title="Update outreach sequence list" placement="right" arrow>
                                        <IconButton
                                            aria-label="refresh"
                                            onClick={handleRefreshSequenceClick}
                                            disabled={!sequenceMasterListFetched}
                                            sx={{
                                                ...(isRefreshing ? rotatingStyle : {}),
                                                backgroundColor: 'transparent',
                                                marginTop: '15px',
                                                '&:hover': {
                                                    backgroundColor: 'transparent',
                                                },
                                            }}
                                        >
                                            <RefreshIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                {autoPersonaSelection && <div style={{ gap: '10px', display: 'flex', marginTop: "20px" }}>
                                    <Typography variant="body2" className="MuiTypography-root MuiTypography-body1" color={"#16124C"} fontSize={"16px"} fontWeight={"400"} marginTop={"10px"} gutterBottom>
                                        Auto Persona Enabled
                                    </Typography>
                                    <ToggleButton
                                        value={isAutoPersonaSelectionEnabled}
                                        name='isAutoPersonaSelectionEnabled'
                                        variant="tertiary"
                                        onChange={(value) => { setIsAutoPersonaSelectionEnabled(!isAutoPersonaSelectionEnabled) }}
                                    />
                                </div>}
                                <div style={{ gap: '10px', display: 'flex' }}>
                                    <Button
                                        fontSize={"14px"}
                                        marginTop={"20px"}
                                        onClick={handleConfigureSteps}
                                        size="small"
                                        variant="primary"
                                        disabled={!selectedSequence}
                                    >
                                        Configure Steps
                                    </Button>
                                    <Button fontSize={"14px"} marginTop={"20px"} onClick={() => { setAddCrmSequence(false); }} size="small" variant="primary">
                                        Back
                                    </Button>

                                    <style>{`
                                            @keyframes rotate {
                                            0% {
                                                transform: rotate(0deg);
                                            }
                                            100% {
                                                transform: rotate(360deg);
                                        }
                                        }
                                    `}</style>
                                </div>
                            </div>
                        )}
                        {(addSequenceName || (addCrmSequence && integrationType == "salesforce")) && integrationType != "pipeliner" && (
                            <div>
                                <div style={{ marginTop: '10px' }}>
                                    <TextField
                                        label="Sequence Name"
                                        variant="outlined"
                                        margin="normal"
                                        name="sequenceName"
                                        fullWidth
                                        value={manualSequenceName}
                                        onChange={(e) => setManualSequenceName(e.target.value)}
                                        required
                                    />
                                </div>
                                {autoPersonaSelection && <div style={{ gap: '10px', display: 'flex', marginTop: "20px" }}>
                                    <Typography variant="body2" className="MuiTypography-root MuiTypography-body1" color={"#16124C"} fontSize={"16px"} fontWeight={"400"} marginTop={"10px"} gutterBottom>
                                        Auto Persona Enabled
                                    </Typography>
                                    <ToggleButton
                                        value={isAutoPersonaSelectionEnabled}
                                        name='isAutoPersonaSelectionEnabled'
                                        variant="tertiary"
                                        onChange={(value) => { setIsAutoPersonaSelectionEnabled(!isAutoPersonaSelectionEnabled) }}
                                    />
                                </div>}
                                <div style={{ gap: '10px', display: 'flex' }}>
                                    <Button
                                        fontSize={"14px"}
                                        marginTop={"20px"}
                                        onClick={() => {
                                            setHidePopupSequenceOption(true);
                                            setManualSequenceBuilder(true);
                                            setAddSequenceName(false);
                                        }}
                                        size="small"
                                        variant="primary"
                                        disabled={!manualSequenceName}
                                    >
                                        Continue
                                    </Button>
                                    <Button
                                        fontSize={"14px"}
                                        marginTop={"20px"}
                                        onClick={() => { setAddSequenceName(false); setAddCrmSequence(false) }}
                                        size="small"
                                        backgroundColor={"#efefef"}
                                        borderColor={"#efefef"}
                                        color={"#000"}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </div>
                        )}
                        {addCrmSequence && integrationType === "pipeliner" && (
                            <div style={{ marginTop: '10px' }}>
                                <div style={{ gap: '10px', display: 'flex' }}>
                                    <Autocomplete
                                        className="w-[300px] mt-6 text-[12px]"
                                        options={sequenceDropDown}
                                        fullWidth
                                        disabled={isRefreshing}
                                        getOptionLabel={(option) =>
                                            `${option.label}${option.isUsed ? " (Already Used)" : ""}`
                                        }
                                        onChange={(event, value) => setSelectedSequence(value)}
                                        renderInput={(params) => <TextField {...params} label="Search Sequence" />}
                                        renderOption={(props, option) => (
                                            <li {...props} key={option.value} style={{ opacity: option.isUsed ? 0.5 : 1, pointerEvents: option.isUsed ? 'none' : 'auto' }}>
                                                {option.label}
                                                {option.isUsed && (
                                                    <Typography variant="caption" sx={{ color: "red", ml: 1 }}>
                                                        (Already Used)
                                                    </Typography>
                                                )}
                                            </li>
                                        )}
                                    />
                                    <Tooltip title="Update outreach sequence list" placement="right" arrow>
                                        <IconButton
                                            aria-label="refresh"
                                            onClick={handleRefreshSequenceClick}
                                            disabled={!sequenceMasterListFetched}
                                            sx={{
                                                ...(isRefreshing ? rotatingStyle : {}),
                                                backgroundColor: 'transparent',
                                                marginTop: '15px',
                                                '&:hover': {
                                                    backgroundColor: 'transparent',
                                                },
                                            }}
                                        >
                                            <RefreshIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                {autoPersonaSelection && <div style={{ gap: '10px', display: 'flex', marginTop: "20px" }}>
                                    <Typography variant="body2" className="MuiTypography-root MuiTypography-body1" color={"#16124C"} fontSize={"16px"} fontWeight={"400"} marginTop={"10px"} gutterBottom>
                                        Auto Persona Enabled
                                    </Typography>
                                    <ToggleButton
                                        value={isAutoPersonaSelectionEnabled}
                                        name='isAutoPersonaSelectionEnabled'
                                        variant="tertiary"
                                        onChange={(value) => { setIsAutoPersonaSelectionEnabled(!isAutoPersonaSelectionEnabled) }}
                                    />
                                </div>}
                                <div style={{ gap: '10px', display: 'flex' }}>
                                    <Button
                                        fontSize={"14px"}
                                        marginTop={"20px"}
                                        onClick={handleConfigureSteps}
                                        size="small"
                                        variant="primary"
                                        disabled={!selectedSequence}
                                    >
                                        Configure Steps
                                    </Button>
                                    <Button fontSize={"14px"} marginTop={"20px"} onClick={() => { setAddCrmSequence(false); }} size="small" variant="primary">
                                        Back
                                    </Button>

                                    <style>{`
                                            @keyframes rotate {
                                            0% {
                                                transform: rotate(0deg);
                                            }
                                            100% {
                                                transform: rotate(360deg);
                                        }
                                        }
                                    `}</style>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </ModalWrapper>

            {sequenceStepList.length > 0 && (
                <SequenceStepList
                    sequenceStepList={sequenceStepList}
                    sequenceName={crmSequenceName}
                    frameworkList={frameworkListOld}
                    defaultEmail={defaultEmailSetting}
                    defaultLinkedin={defaultLinkedinSetting}
                    toneList={toneList}
                    wordCountList={wordCountList}
                    contextList={contextListOld}
                    sequenceId={sequenceId}
                    onBack={handleBack}
                    handleSequenceSteps={handleSaveSequence}
                    autoPersonaSelectionSetting={isAutoPersonaSelectionEnabled}
                />
            )}

            {savedSequenceStepList.length > 0 && checkIntegrationType !== 'manual' && checkIntegrationType !== 'salesforce' && checkIntegrationType !== 'pipeliner' && (
                <SavedSequenceStepList
                    sequenceStepList={savedSequenceStepList}
                    savedSequence={savedSequenceList}
                    sequenceName={crmSequenceName}
                    frameworkList={frameworkList}
                    defaultEmail={defaultEmailSetting}
                    defaultLinkedin={defaultLinkedinSetting}
                    toneList={toneList}
                    wordCountList={wordCountList}
                    contextList={contextList}
                    onBack={handleBack}
                    handleSequenceSteps={handleUpdateSequenceSteps}
                    autoPersonaSelectionSetting={autoPersonaSelectionSequence}
                />
            )}

            {manualSequenceBuilder &&
                <ManualSequenceBuilder
                    frameworkList={frameworkListOld}
                    defaultEmail={defaultEmailSetting}
                    defaultLinkedin={defaultLinkedinSetting}
                    sequenceName={manualSequenceName}
                    toneList={toneList}
                    wordCountList={wordCountList}
                    contextList={contextListOld}
                    onBack={handleBack}
                    handleSequenceSteps={handleManualSaveSequence}
                    integrationType={integrationType}
                    autoPersonaSelectionSetting={isAutoPersonaSelectionEnabled}
                />}

            {savedSequenceStepList.length > 0 && (checkIntegrationType === 'manual' || checkIntegrationType === 'salesforce') &&
                (<SavedManualSequenceStepList
                    sequenceStepList={savedSequenceStepList}
                    emailStepOptions={manualSavedEmailOptions}
                    followUpOptions={manualSavedFollowUpOptions}
                    sequenceName={crmSequenceName}
                    sequenceId={sequenceId}
                    frameworkList={frameworkList}
                    defaultEmail={defaultEmailSetting}
                    defaultLinkedin={defaultLinkedinSetting}
                    toneList={toneList}
                    wordCountList={wordCountList}
                    contextList={contextList}
                    onBack={handleBack}
                    handleSequenceSteps={handleUpdateSequenceSteps}
                    crmsequence={addCrmSequence}
                    integrationType={integrationType}
                    selectedManualSequence={selectedManualSequence}
                    autoPersonaSelectionSetting={autoPersonaSelectionSequence}
                />)
            }
            {pipelinerSequenceStepBuilder.length > 0 && integrationType === 'pipeliner' &&
                (<PipelinerSeqeunceStepBuilder
                    sequenceStepList={pipelinerSequenceStepBuilder}
                    frameworkList={frameworkListOld}
                    defaultEmail={defaultEmailSetting}
                    sequenceName={crmSequenceName}
                    toneList={toneList}
                    wordCountList={wordCountList}
                    contextList={contextListOld}
                    onBack={handleBack}
                    handleSequenceSteps={handlePipelinerSaveSequence}
                    integrationType={integrationType}
                    autoPersonaSelectionSetting={isAutoPersonaSelectionEnabled}
                    pipelinerField={pipelinerField}
                />)
            }
            {savedSequenceStepList.length > 0 && checkIntegrationType === 'pipeliner' &&
                (<SavedPipelinerSequenceStepList
                    sequenceStepList={savedSequenceStepList}
                    emailStepOptions={manualSavedEmailOptions}
                    followUpOptions={manualSavedFollowUpOptions}
                    sequenceName={crmSequenceName}
                    sequenceId={sequenceId}
                    frameworkList={frameworkList}
                    defaultEmail={defaultEmailSetting}
                    defaultLinkedin={defaultLinkedinSetting}
                    toneList={toneList}
                    wordCountList={wordCountList}
                    contextList={contextList}
                    onBack={handleBack}
                    handleSequenceSteps={handleUpdateSequenceSteps}
                    integrationType={integrationType}
                    selectedManualSequence={selectedManualSequence}
                    autoPersonaSelectionSetting={autoPersonaSelectionSequence}
                    pipelinerField={pipelinerField}
                />)
            }
        </div>
    );
}

export default AutomatedEmailSequenceRightSection2;
