import React, { useState } from "react";
import { Typography, Box, SxProps } from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface SmartTextProps {
  text: string;
  length?: number;
  color?: string;
  style?: React.CSSProperties;
}

const formatMarkdown = (text: string) => {
  return text
    .replace(/• /g, "\n- ") // Replace custom bullets with Markdown-compatible dashes
    .replace(/(\S)\n-/g, "$1\n\n-") // Ensure a blank line before a list
    .replace(/(\S):\n/g, "$1:\n\n") // Add blank line after a colon
    .replace(/\n(\s*\n)+/g, "\n\n"); // Condense multiple blank lines to a single one
};

const SmartText: React.FC<SmartTextProps> = ({
  text = "",
  length = 20,
  color,
  style,
}) => {
  const [showLess, setShowLess] = useState(true);
  const isTextTrimmed = text.length > (length ?? 20);

  const renderedContent = showLess
    ? formatMarkdown(text.slice(0, length)) + (isTextTrimmed ? "..." : "")
    : formatMarkdown(text);

  return (
    <Box>
      <Typography
        component="div"
        color={color || "#57585C"}
        sx={{
          fontFamily: '"Inter", sans-serif',
          fontSize: "13px",
          overflow: "hidden",
          ...style,
        } as SxProps}
      >
        <ReactMarkdown
          children={renderedContent}
          remarkPlugins={[remarkGfm]}
          components={{
            // Bold text
            strong: ({ children }) => (
              <strong style={{ fontWeight: 600, fontFamily: "sans-serif" }}>
                {children}
              </strong>
            ),
            // Subheadings (e.g., "In retail")
            em: ({ children }) => (
              <span style={{ fontWeight: 600, fontStyle: "normal", color: "#333" }}>
                {children}
              </span>
            ),
            // Paragraphs
            p: ({ children }) => (
              <p style={{ margin: "0 0 5px", lineHeight: "1.6" }}>{children}</p>
            ),
            // Unordered List
            ul: ({ children }) => (
              <ul style={{ paddingLeft: "20px", margin: "10px 0", listStyleType: "disc" }}>
                {children}
              </ul>
            ),
            // Ordered List
            ol: ({ children }) => (
              <ol style={{ paddingLeft: "20px", margin: "10px 0", listStyleType: "decimal" }}>
                {children}
              </ol>
            ),
            // List Item
            li: ({ children, node }) => {
              const isHeading =
                typeof children[0] === "string" &&
                /^[A-Za-z\s]+:/.test(children[0].toString()); // Ends with a colon

              const isSubItem = node.position?.start.column! > 2;

              return (
                <li
                  style={{
                    // marginBottom: "5px",
                    fontSize: isHeading ? "14px" : isSubItem ? "12px" : "13px",
                    fontWeight: isHeading ? "bold" : isSubItem ? "normal" : "bold",
                    color: isHeading ? "#000" : "#555",
                    paddingLeft: isSubItem ? "10px" : "0",
                    listStyleType: isHeading ? "none" : undefined, // Remove marker for headings
                  }}
                >
                  {children}
                </li>
              );
            },
            // Headings
            h1: ({ children }) => (
              <h1 style={{ fontSize: "1.5rem", fontWeight: "bold", marginBottom: "10px" }}>
                {children}
              </h1>
            ),
            h2: ({ children }) => (
              <h2 style={{ fontSize: "1.3rem", fontWeight: "bold", marginBottom: "8px" }}>
                {children}
              </h2>
            ),
            h3: ({ children }) => (
              <h3 style={{ fontSize: "1.1rem", fontWeight: "bold", marginBottom: "6px" }}>
                {children}
              </h3>
            ),
            // Blockquotes
            blockquote: ({ children }) => (
              <blockquote
                style={{
                  borderLeft: "4px solid #ccc",
                  paddingLeft: "10px",
                  color: "#555",
                  fontStyle: "italic",
                  margin: "10px 0",
                }}
              >
                {children}
              </blockquote>
            ),
            // Code blocks
            code: ({ children }) => (
              <code
                style={{
                  backgroundColor: "#f5f5f5",
                  padding: "2px 4px",
                  borderRadius: "4px",
                  fontFamily: "monospace",
                }}
              >
                {children}
              </code>
            ),
          }}
        />
      </Typography>

      {isTextTrimmed && (
        <Typography
          color={"#0094E3"}
          fontSize={"13px"}
          fontWeight={600}
          component="span"
          sx={{ cursor: "pointer", display: "block", marginTop: "5px" }}
          onClick={() => setShowLess(!showLess)}
        >
          &nbsp; {showLess ? "view more" : "show less"}
        </Typography>
      )}
    </Box>
  );
};

export default SmartText;
