import React, { useEffect, useRef, useState } from "react";
import FrameworkAndContextTableBody from "./FrameworkAndContextTableBody";
import FrameworkAndContextTableHeader from "./FrameworkAndContextTableHeader";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import userService from "services/userService";
import { Backdrop,  Box as MuiBox, Checkbox, InputAdornment, TextField, Typography, IconButton, Popper, List, ListItem, Paper, ClickAwayListener } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import { SnackBar } from '@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar';
import { useAppSelector } from "hooks";
import { useDispatch } from "react-redux";
import { setMemberListingData } from "./store/memberListingReducer";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { ModalWrapper } from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { ErrorMessage, Form, Formik, Field } from 'formik';
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { Flex } from '@evabot/eva-react-core-library/dist/atoms/Flex/Flex';
import * as yup from 'yup';
import Select from 'react-select';
import { FormikTextArea } from "@evabot/eva-react-core-library/dist/atoms/FormikTextArea/FormikTextArea";
import MenuItem from '@mui/material/MenuItem';
import { Select as Select2 } from '@mui/material';
import SearchBar from "components/TailwindComponents/SearchBar";
import { ToggleButton } from '@evabot/eva-react-core-library/dist/atoms/ToggleButton/ToggleButton';
import { axiosHelperCore } from "utils";
import AIIconButton from "./component/AIIconButton";
import CustomDataInfoBox from "./component/CustomDataInfoBox";
import TagEditor from "./component/TagEditor";
import { HighlightBox } from "./component/style";
import InfoIcon from "./component/InfoIcon";
import MemoRevenoidLoader from "components/RevenoidLoader/RevenoidLoader";

// const StatusValues = ["All Framework/Context", "Framework : LinkedIn", "Framework : Email", "Context : LinkedIn", "Context : Email"];
const StatusValues = [{ label: "All Framework/Context", value: "All Framework/Context" }, { label: "Framework : Email", value: "Framework : Email" }, { label: "Framework : CallPrep", value: "Framework : CallPrep" }, { label: "Context : LinkedIn", value: "Context : LinkedIn" }, { label: "Context : Email", value: "Context : Email" }, { label: "Context : CallPrep", value: "Context : CallPrep" }];
const AddFrameworkAndContextSchema = yup.object().shape({
    name: yup.string().required("Name is required."),
    content: yup.string().required("Description is required."),
    addType: yup.string().required("Please select a type."),
    frameworkandcontexttype: yup.string().required("Please select a Framework/Context Type."),
});

const FrameworkOrContextTypeOptions = [
    { value: "framework", label: "Framework" },
    { value: "context", label: "Context" },
];

const emailorlinkedinTypeOptions = [
    { value: "email", label: "Email" },
    { value: "linkedin", label: "LinkedIn" },
    { value: "callprep", label: "callPrep" },
];


function FrameworkAndContextTable(props) {
    const { tabView } = props;
    console.log(tabView);
    const [hideModal, setHideModal] = useState(true);
    const [loading, setLoading] = useState(false);
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [showSnackBarInModal, setShowSnackBarInModal] = useState(false);
    const [snackbarMessageInModal, setSnackbarMessageInModal] = useState('');
    const [snackbarTypeInModal, setSnackbarTypeInModal] = useState('success');
    const [emailorlinkedinType, setEmailorlinkedinType] = useState(emailorlinkedinTypeOptions);
    const [frameworkOrContextType, setFrameworkOrContextType] = useState('');
    const [filterStatus, setFilterStatus] = useState("All Framework/Context");
    const [membersData, setMembersData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [dataRepositoryTags, setDataRepositoryTags] = useState([]);
    const [contextMaxWordLimit, setContextMaxWordLimit] = useState(150);
    const [customData, setCustomData] = useState('');
    const [mlCustomDataResponse, setMlCustomDataResponse] = useState('');
    const [mlRepoIdsResponse, setMlRepoIdsResponse] = useState([]);
    const [query, setQuery] = useState('');
    const [cursorPosition, setCursorPosition] = useState(0);
    const [showDropdown, setShowDropdown] = useState(false);
    const [hasSelectedTag, setHasSelectedTag] = useState(false);
    const [filteredTags, setFilteredTags] = useState([]);
    const [usedTags, setUsedTags] = useState([]);
    const [isFullTextVisible, setIsFullTextVisible] = useState(false);
    const [hasStartedTyping, setHasStartedTyping] = useState(false);
    const [dataRepositoryFeatureEnabled, setDataRepositoryFeatureEnabled] = useState(false);
    const [originalQuery, setOriginalQuery] = useState('');
    const dispatch = useDispatch();
    const searchValue = useRef("");
    const inputRef = useRef(null);
    const [tagData, setTagData] = useState<{
        customData: string;
        repoIds: string[];
        query: string;
      }>({
        customData: "",
        repoIds: [],
        query: "",
    });

    const userOrganizationId = useAppSelector(
        (state) => state.memberListingState?.tabViewState?.isOrgId
    );


    const selectedUserId = useAppSelector(
        (state) => state.memberListingState?.tabViewState?.isTabViewMemberData?.userId
    );

    const loggedInUserOrgId = useAppSelector(
        (state) => state.profileState.profileData.organizationId
    )

    const userId = useAppSelector(
        (state) => state.profileState.profileData.userId
    );

    const openEditFormpopup = () => {
        setHideModal(false);
    }

    const wordCount = (text) => {
        return text ? text.split(/\s+/).length : 0;
    };

    const toggleTextVisibility = () => {
        setIsFullTextVisible(!isFullTextVisible);
    };

    const handleQueryChange = (event) => {
        setQuery(event.target.value);
    };

    const fetchReport = async () => {
        try {
            setLoading(true);
            let obj = {};
            if (tabView) {
                obj = { "organizationId": userOrganizationId };
            } else {
                obj = { "organizationId": loggedInUserOrgId }
            }
            userService.getAllMemberByOrgId(obj)
                .then((response) => {

                    let membersData = response?.data;

                    console.log("membersDatamembersData", membersData);

                    let modifiedMemberData = []
                    membersData.forEach(member => {
                        member.frameworks?.forEach(framework => {
                            if (!framework.isDeleted) {
                                modifiedMemberData.push({
                                    userId: member.userId, lastName: member.lastName, firstName: member.firstName, email: member.email,
                                    type: "framework", frameworkName: framework?.name, frameworkValue: framework?.framework, id: framework?.frameworkId,
                                    isPublic: framework?.isPublic, objectId: framework?._id, linkedinOremail: framework?.type, isExtensionVisibilityEnabled: framework?.isExtensionVisibilityEnabled, defaultSettings : member?.defaultSettings

                                })
                            }
                        });
                        member.contexts?.forEach(context => {
                            if (!context.isDeleted) {
                                modifiedMemberData.push({
                                    userId: member.userId, lastName: member.lastName, firstName: member.firstName, email: member.email,
                                    type: "context", contextName: context?.name, contextValue: context?.context, id: context?.contextId,
                                    isPublic: context?.isPublic, objectId: context?._id, linkedinOremail: context?.type, isExtensionVisibilityEnabled: context?.isExtensionVisibilityEnabled, defaultSettings : member?.defaultSettings, 
                                    customData : context?.customData, repoIds: context?.repoIds, query : context?.query
                                })
                            }
                        });
                    });

                    console.log("modifiedMemberData", modifiedMemberData);

                    dispatch(setMemberListingData(modifiedMemberData));
                    setFilteredMembers(modifiedMemberData);
                    setMembersData(modifiedMemberData);
                    setContextMaxWordLimit(response?.contextWordMaxLimit);
                    setLoading(false);
                })
        } catch (error) {
            console.error("Error fetching report:", error);
            setLoading(false);
        }

    };
    console.log("selectedUserIdselectedUserId", selectedUserId);

    const handleAddContextAndFramework = async (values) => {
        try {

            if (values.addType === 'context') {
                if (wordCount(values.content) > contextMaxWordLimit) {
                    setSnackbarMessageInModal(`The content exceeds the word limit of ${contextMaxWordLimit} words.`);
                    setSnackbarTypeInModal("error");
                    setShowSnackBarInModal(true);
                    return;
                }
            }

            const findSimilarName = membersData?.filter(item => item?.linkedinOremail === values.frameworkandcontexttype)?.find(item => item?.frameworkName?.toLowerCase() === values?.name?.toLowerCase() || item?.contextName?.toLowerCase() === values?.name?.toLowerCase());

            if (findSimilarName) {
                setSnackbarMessageInModal('Similar name framework/context not allowed');
                setShowSnackBarInModal(true);
                setSnackbarTypeInModal('error');
                setLoading(false);
                return;
            }
            const obj : any = { "userId": selectedUserId ? selectedUserId : values.userId, "name": values.name, "value": values.content, "keyType": values.addType, "type": values.frameworkandcontexttype, "isPublic":values.isPublic };
            if (values.addType === 'context') {
                if (mlCustomDataResponse) {
                    obj.customData = mlCustomDataResponse;
                }
                if (Array.isArray(mlRepoIdsResponse) && mlRepoIdsResponse.length > 0) {
                    obj.repoIds = mlRepoIdsResponse;
                }
                if (query) {
                    obj.query = query;
                }
            }
            const contextFrameworkAdded = await userService.addMemberKey(obj);
            if (contextFrameworkAdded?.success) {
                fetchReport();
                setLoading(false);
                setSnackbarMessage('Framework/Context Added');
                setShowSnackBar(true);
                setSnackbarType('success');
            } else {
                setSnackbarMessage('Error Adding Framework/Context');
                setShowSnackBar(true);
                setSnackbarType('error');
                setLoading(false);
            }
            setMlCustomDataResponse("");
            setMlRepoIdsResponse([]);
            setFrameworkOrContextType("");
            setQuery("");
            setOriginalQuery("");
        } catch (error) {
            console.error('Error Adding Framework/Context', error);
            setLoading(false);
        }
        setFilterStatus("All Framework/Context");
        setQuery("");
        setMlCustomDataResponse("");
        setMlRepoIdsResponse([]);
        setHideModal(true);
    }

    const handleSearchChange = (e) => {
        searchValue.current = e?.target?.value?.toLowerCase();
        seachDataPipe();

    };

    const seachDataPipe = async () => {
        let searchContext = searchValue.current;
        let filteredData = membersData;
        if (filterStatus && filterStatus !== "All Framework/Context") {
            const splitStatus = filterStatus.split(":");
            const filterType = splitStatus[0]?.trim()?.toLowerCase();
            const filterLinkedinOrEmail = splitStatus[1]?.trim()?.toLowerCase();

            filteredData = filteredData.filter(item => {
                const type = item.type?.toLowerCase();
                const linkedinOrEmail = item.linkedinOremail?.toLowerCase();
                return type === filterType && linkedinOrEmail === filterLinkedinOrEmail;
            });
        }
        if (searchContext.trim() != "") {
            filteredData = filteredData.filter(m => m?.firstName?.toLowerCase().includes(searchContext) || m?.lastName?.toLowerCase().includes(searchContext) || m?.email?.toLowerCase().includes(searchContext) || m?.frameworkName?.toLowerCase().includes(searchContext) || m?.contextName?.toLowerCase().includes(searchContext) || m?.frameworkValue?.toLowerCase().includes(searchContext) || m?.contextValue?.toLowerCase().includes(searchContext));
            setFilteredMembers(filteredData);
        }
        else {
            setFilteredMembers(filteredData);
        }
    }

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        const caretPosition = event.target.selectionStart;
    
        setQuery(inputValue);
    
        const lastAtSymbolIndex = inputValue.lastIndexOf('@', caretPosition - 1);
        if (
            lastAtSymbolIndex !== -1 &&
            inputValue.charAt(caretPosition - 1) !== ' ' &&
            !hasSelectedTag
        ) {
            setShowDropdown(true);
            setCursorPosition(caretPosition);
            const searchTerm = inputValue.slice(lastAtSymbolIndex + 1, caretPosition).toLowerCase();
            const matchedTags = dataRepositoryTags.filter((tag) =>
                tag.toLowerCase().includes(searchTerm)
            );
            setFilteredTags(matchedTags)
        } else {
            setShowDropdown(false); 
        }
    };

    const handleTagSelect = (tag) => {
        const lastAtSymbolIndex = query.lastIndexOf('@', cursorPosition - 1);
        const newQuery =
          query.slice(0, lastAtSymbolIndex + 1) +
          `{${tag}}` +
          query.slice(cursorPosition);
    
        setQuery(newQuery);
        setShowDropdown(false);
        setHasSelectedTag(true);
        inputRef.current.focus();

        setUsedTags((prevUsedTags) => [...prevUsedTags, tag]);
    };

    const getDataRepositoryTags = async() => {
        try {
            setLoading(true);

            const obj = {
                userId: userId,
                organizationId: loggedInUserOrgId
            };

            const response = await axiosHelperCore({
                url: `rapport/user/getDataRepositoryTags`,
                method: "POST",
                JSONData: obj
            });

            if(response?.data?.result){
                setDataRepositoryTags(response?.data?.result);
                setDataRepositoryFeatureEnabled(response?.data?.dataRepositoryFeatureEnabled);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetchong content library tags:", error);
        }
    }

    const resetTagSelection = (inputValue, caretPosition) => {
        if (inputValue.charAt(caretPosition - 1) === '@') {
            setHasSelectedTag(false);
        }
    };
    

    const handleInputChangeWithReset = (event) => {
        handleInputChange(event);
        resetTagSelection(event.target.value, event.target.selectionStart);
    };

    const handleDescriptionChange = (event, setFieldValue) => {
        const value = event.target.value;
        setFieldValue("content", value);

        if (value.length > 0) {
            setHasStartedTyping(true);
        } else {
            setHasStartedTyping(false);
        }
    };

    const shouldShowCustomDataInfoBox = () => {
        return frameworkOrContextType === 'context' && !hasStartedTyping && mlCustomDataResponse;
    };

    const handlePopupCancel = () => {
        setHideModal(true);
        setMlCustomDataResponse("");
        setMlRepoIdsResponse([]);
        setFrameworkOrContextType("");
        setQuery("");
        setOriginalQuery("");
    }

    useEffect(() => {
        if (userOrganizationId || loggedInUserOrgId) {
            fetchReport();
            getDataRepositoryTags();
        }
    }, [userOrganizationId, loggedInUserOrgId])

    useEffect(() => {
        if (frameworkOrContextType === "framework") {
            setEmailorlinkedinType([{ value: "email", label: "Email" }, { value: "callprep", label: "callPrep" }]);
        } else {
            setEmailorlinkedinType(emailorlinkedinTypeOptions);
        }

    }, [frameworkOrContextType]);

    useEffect(() => {

        let filteredData = membersData;

        let searchContext = searchValue.current;
        //alert(filterStatus);
        if (searchContext.trim() != "") {
            filteredData = filteredData.filter(m => m?.firstName?.toLowerCase().includes(searchContext) || m?.lastName?.toLowerCase().includes(searchContext) || m?.email?.toLowerCase().includes(searchContext) || m?.frameworkName?.toLowerCase().includes(searchContext) || m?.contextName?.toLowerCase().includes(searchContext) || m?.frameworkValue?.toLowerCase().includes(searchContext) || m?.contextValue?.toLowerCase().includes(searchContext));
            setFilteredMembers(filteredData);
        }

        if (filterStatus && filterStatus !== "All Framework/Context") {
            const splitStatus = filterStatus.split(":");
            const filterType = splitStatus[0]?.trim()?.toLowerCase();
            const filterLinkedinOrEmail = splitStatus[1]?.trim()?.toLowerCase();

            filteredData = filteredData.filter(item => {
                const type = item.type?.toLowerCase();
                const linkedinOrEmail = item.linkedinOremail?.toLowerCase();
                return type === filterType && linkedinOrEmail === filterLinkedinOrEmail;
            });
        }


        setFilteredMembers(filteredData);

    }, [filterStatus])


    useEffect(() => {
        seachDataPipe();
    }, [membersData])


    return (
        <>
            <SnackBar
                iconName={"info"}
                message={snackbarMessage}
                setShowSnackBar={setShowSnackBar}
                show={showSnackBar}
                timeout={4000}
                anchor={"top-right"}
                type={snackbarType}
            />
            <Box >
                {loading && <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                    open={true}
                >
                   <MemoRevenoidLoader />
                </Backdrop>}
                {tabView && <Flex justifyContent={"flex-end"} marginBottom={2} position={"fixed"} top={"11.5%"} right={"3%"}>
                    <Button onClick={openEditFormpopup} variant="secondary">
                        Add Framework/Context
                    </Button>

                </Flex>}

                {!tabView &&
                    <Flex justifyContent={"space-between"} alignItems="center" marginBottom={"20px"} gap="20px">
                        <Flex >
                            {/* <Text fontWeight="400" fontSize="32px">Framework/Context</Text> */}
                            <SearchBar placeholder="Search via name/framework/context" onChange={handleSearchChange} />
                        </Flex>
                        <Flex justifyContent={"flex-end"}>
                            <Text fontSize={"12px"} marginRight={"15px"} alignSelf={"center"}>Filter By : </Text>
                            <Box display={"flex"} marginRight={"15px"}>
                                <Select
                                    placeholder="Type: e.g. Context:LinkedIn"
                                    options={StatusValues}
                                    onChange={(e) => setFilterStatus(e.value)}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            width: "230px",
                                            height: "48px",
                                            border: "6px",
                                            fontSize: "12px"
                                        }),
                                    }}

                                />
                            </Box>
                            <Flex justifyContent={"flex-end"}>
                                <Button onClick={openEditFormpopup} variant="secondary" fontSize={"14px"} padding={"10px 10px"}>
                                    Add Framework/Context
                                </Button>
                            </Flex>
                        </Flex>
                    </Flex>}

                <FrameworkAndContextTableHeader />
                <FrameworkAndContextTableBody data={filteredMembers} fetchData={fetchReport} contextWordMaxLimit={contextMaxWordLimit} dataRepositoryTagsList={dataRepositoryTags} dataRepositoryFeatureCheck={dataRepositoryFeatureEnabled}/>
                <Flex borderBottomLeftRadius={"8px"} borderBottomRightRadius={"8px"} background={"#fff"} height={"10px"}></Flex>

                <ModalWrapper
                    width="600px"
                    height="max-content"

                    hideModal={hideModal}
                    setHideModal={() => {
                        setFrameworkOrContextType("");
                        setHideModal(true);
                    }}
                    bgcolor={"rgba(0, 0, 0, 0.8)"}
                    padding="24px"
                >

                    <>
                        <SnackBar
                            iconName={"info"}
                            message={snackbarMessageInModal}
                            setShowSnackBar={setShowSnackBarInModal}
                            show={showSnackBarInModal}
                            timeout={4000}
                            anchor={"top-right"}
                            type={snackbarTypeInModal}
                        />
                        <Text fontWeight="400" fontSize="24px" marginBottom={4}>Add Framework/Context</Text>
                        <Formik
                            key={isModalOpen.toString()}
                            initialValues={{
                                name: '',
                                content: '',
                                addType: '',
                                frameworkandcontexttype: '',
                                userId: userId,
                                isPublic:false
                            }}
                            onSubmit={(values) => {
                                console.log('Submitting edited data:', values);
                                handleAddContextAndFramework(values);
                            }}
                            validationSchema={AddFrameworkAndContextSchema}

                        >
                            {({ setFieldValue, values }) => {
                                const handleSendQuery = async () => {
                                    try {
                                        setLoading(true);
                                        const obj = {
                                            payload : {
                                                userId : userId,
                                                query : query.replace(/@/g, ''),
                                                tags : new Set(usedTags)
                                            }
                                        };
                                        const response = await axiosHelperCore({
                                            url: `rapport/user/generateCustomData`,
                                            method: "POST",
                                            JSONData: obj
                                        });
                            
                                        if(response){
                                            setMlCustomDataResponse(response?.data?.result?.customData);
                                            setMlRepoIdsResponse(response?.data?.result?.repoIds);
                                            setFieldValue("isPublic", frameworkOrContextType === "context" && true);
                                        }
                                        setLoading(false);
                                    } catch (error) {
                                      console.error('Error calling ML API:', error);
                                    }
                                };
                            
                            
                            return (
                                <Form style={{ maxHeight: 600, overflowY: 'auto', scrollbarWidth:"none"}}>
                                    <Flex flexDirection="column">
                                        <Flex padding={"0 10px"} marginBottom={3} flexDirection="column">
                                            <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Select Type</Text>
                                            <Select
                                                className="single-select"
                                                placeholder="Select Type"
                                                name="addType"
                                                options={FrameworkOrContextTypeOptions}
                                                onChange={(selectedOption) => {
                                                    setFrameworkOrContextType(selectedOption.value);
                                                    setFieldValue("addType", selectedOption ? selectedOption.value : '');
                                                }}

                                                styles={{
                                                    control: (provided: Record<string, unknown>, state: any) => ({
                                                        ...provided,
                                                        width: '535px',
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        width: '535px',
                                                    }),
                                                }}

                                            />
                                            <ErrorMessage
                                                name="addType"
                                                component="div"
                                                className="custom-error-message"
                                            />
                                        </Flex>
                                        <Flex padding={"0 10px"} marginBottom={3} flexDirection="column">
                                            <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Select Messaging Type</Text>
                                            <Select
                                                className="single-select"
                                                placeholder="Select Type"
                                                name="frameworkandcontexttype"
                                                options={emailorlinkedinType}
                                                onChange={(selectedOption) => {
                                                    setFieldValue("frameworkandcontexttype", selectedOption ? selectedOption.value : '');
                                                }}

                                                styles={{
                                                    control: (provided: Record<string, unknown>, state: any) => ({
                                                        ...provided,
                                                        width: '535px',
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        width: '535px',
                                                    }),
                                                }}

                                            />
                                            <ErrorMessage
                                                name="frameworkandcontexttype"
                                                component="div"
                                                className="custom-error-message"
                                            />
                                        </Flex>
                                        <Flex padding={"0 10px"} flexDirection="column">
                                            <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Name</Text>
                                            <FormikInput marginBottom={"-5px"}
                                                name="name"
                                                placeholder="Name"
                                                fullSize
                                            />
                                        </Flex>
                                        <Flex padding={"0 10px"} flexDirection="column" >
                                            <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Description</Text>
                                            <FormikTextArea marginBottom={0}
                                                name="content"
                                                placeholder="description"
                                                width={"95%"}
                                                onChange={(event) => handleDescriptionChange(event, setFieldValue)}
                                            />
                                        </Flex>
                                        <Flex padding={"3px 10px"} flexDirection="row" alignItems={"center"} gap="5px">
                                            <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Make it visible to the team</Text>
                                            <ToggleButton
                                                variant='tertiary'
                                                value={values.isPublic}
                                                name='isActive'
                                                onChange={
                                                    (value) =>  {
                                                        setFieldValue("isPublic",value);
                                                    }
                                                }
                                            />

                                        </Flex>
                                        {dataRepositoryFeatureEnabled && frameworkOrContextType === 'context'&& ( <>
                                            {shouldShowCustomDataInfoBox() && (
                                            <HighlightBox>
                                                <InfoIcon />
                                                <Typography variant="subtitle2" sx={{ color: "#FFF", mt: 0 }}>
                                                We have pulled your custom data. Please tell us how to use it
                                                in "Context details"
                                                </Typography>
                                            </HighlightBox>
                                            )}
                                            <Text variant="bodyRegular" fontWeight={"600"}  paddingLeft={"5px"} paddingTop={"10px"}>Add Custom Data</Text>
                                            <TagEditor
                                                query={query}
                                                setOriginalQuery={setOriginalQuery}
                                                originalQuery={originalQuery}
                                                customData={mlCustomDataResponse}
                                                setQuery={(value) => setQuery(value)}
                                                setCustomData={(value) => setMlCustomDataResponse(value)}
                                                onTagAdded={(value) => {
                                                    setFieldValue("isPublic", true);
                                                    setTagData(value);
                                                }}
                                                tagList={dataRepositoryTags}
                                            />
                                        </>)
                                        }
                                        <Flex marginRight={1} marginTop={3} padding={"5px 10px"}>
                                            <Flex justifyContent={"left"}>
                                                <Button type="submit" disabled={query && frameworkOrContextType === 'context' && !mlCustomDataResponse}>Save</Button>
                                            </Flex>
                                            <Flex justifyContent={"right"}>
                                                <Button variant="secondary" type="button" onClick={handlePopupCancel} marginLeft={2}>
                                                    Cancel
                                                </Button>
                                            </Flex>
                                        </Flex>

                                    </Flex>
                                </Form>
                            )}}
                        </Formik>
                    </>
                </ModalWrapper>

            </Box>


        </>
    );
}

export default FrameworkAndContextTable;
