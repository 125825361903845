import React, { useEffect, useState, useRef } from "react";
import { Icon as EvaLibraryIcon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { TextField, Chip, Box, Dialog, DialogActions, Tooltip, DialogContent, DialogTitle, Backdrop } from "@mui/material";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { axiosHelperCore } from "utils";
import { useAppSelector } from "hooks";
import * as XLSX from "xlsx";
import DeleteButton from "containers/FrameworkAndContext/component/DeleteButton";
import EditButton from "containers/FrameworkAndContext/component/EditButton";
import NoRecordFound from "containers/ManagePersonas/component/NoRecordFound";
import DeleteConfirmationPopup from "containers/ManagePersonas/component/DeleteConfirmationPopup";
import AddCustomDataComponent from "./component/AddCustomDataComponent";
import DataRepositoryHeader from "./component/DataRepositoryHeader";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "assets/icons/DeleteIcon";
import AddIcon from "assets/icons/AddIcon";
import "./file.css";
import PreviewDataContent from "./component/PreviewDataContent";
import PdfText from 'react-pdftotext';
import MemoRevenoidLoader from "components/RevenoidLoader/RevenoidLoader";
import SearchBar from "components/TailwindComponents/SearchBar";

const columnHeadings = ["Title", "Tags", "Data"];
const MAX_TAGS_DISPLAY = 2;

function DataRepositoryContent() {

    const [open, setOpen] = useState(false);
    type Severity = 'info' | 'error' | 'warning' | 'success';

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<Severity>('info');

    const [loading, setLoading] = useState(false);
    const [dataRepositoryList, setDataRepositoryList] = useState([]);
    const [previewData, setPreviewData] = useState([]);
    const [isPreviewPopupOpen, setIsPreviewPopupOpen] = useState(false);
    const [isUploadPopupOpen, setIsUploadPopupOpen] = useState(false);
    const [isAddingCustomData, setIsAddingCustomData] = useState(false);
    const [editData, setEditData] = useState(null);
    const [selectedRepo, setSelectedRepo] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isTagsDialogOpen, setIsTagsDialogOpen] = useState(false);
    const [tagsToDisplay, setTagsToDisplay] = useState([]);
    const [pdfDataPreview, setPdfDataPreview] = useState(false);
    const [dataRepositoryOriginalList, setDataRepositoryOriginalList] = useState([]);

    const [expandedRows, setExpandedRows] = useState(new Set());
    const [expandedTagRows, setExpandedTagRows] = useState(new Set());
    const searchValue = useRef("");

    const toggleRowExpansion = (rowIndex) => {
        const newExpandedRows = new Set(expandedRows);
        if (expandedRows.has(rowIndex)) {
            newExpandedRows.delete(rowIndex);
        } else {
            newExpandedRows.add(rowIndex);
        }
        setExpandedRows(newExpandedRows);
    };

    const toggleTagExpansion = (index) => {
        const newExpandedTagRows = new Set(expandedTagRows);
        if (expandedTagRows.has(index)) {
            newExpandedTagRows.delete(index);
        } else {
            newExpandedTagRows.add(index);
        }
        setExpandedTagRows(newExpandedTagRows);
    };

    const loggedInMemberDetails = useAppSelector((state) => state.profileState.profileData);

    const showSnackbar = (message: string, severity: Severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleAddCustomData = () => {
        setEditData(null);
        setIsAddingCustomData(true);
    };

    const handleEditRow = (rowData) => {
        setEditData(rowData);
        setIsAddingCustomData(true);
    };

    const handleSaveCustomData = (newData) => {
        handleSubmitPreviewData(newData);
        setIsAddingCustomData(false);
        setPdfDataPreview(false);
    };

    const handleOpenPopup = (repoData) => {
        setIsPopupOpen(true);
        setSelectedRepo(repoData);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const handleUploadFile = async(e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];

        if (file) {

            const fileType = file.type;

            if(fileType === "application/pdf"){

                try {
                    const text = await PdfText(file);
                    setEditData({ rawData: text });
                    setIsAddingCustomData(true);
                    setPdfDataPreview(true);
                } catch (err) {
                    console.error("Error extracting text:", err);
                    showSnackbar("Failed to extract text from PDF", "error");
                }
            } else {

                const reader = new FileReader();
    
                reader.onload = (event) => {
                    const content = event.target?.result as ArrayBuffer;
                    const data = new Uint8Array(content);
                    const workbook = XLSX.read(data, { type: "array" });
    
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
    
                    const jsonData = XLSX.utils.sheet_to_json(sheet, { raw: false }) as any[];
    
                    const formattedData = jsonData.map((item) => {
                        const normalizedItem = Object.keys(item).reduce((acc, key) => {
                            acc[key.toLowerCase()] = item[key];
                            return acc;
                        }, {} as Record<string, any>);
        
                        return {
                            title: normalizedItem.title,
                            tags: normalizedItem.tags ? normalizedItem.tags.split(",").map(tag => tag.trim()) : [],
                            rawData: normalizedItem.data || ""
                        };
                    });
    
                    setPreviewData(formattedData);
                    setIsPreviewPopupOpen(true);
                };
    
                reader.readAsArrayBuffer(file);
            }
        }
        setIsUploadPopupOpen(false);
    };

    const handleDeleteRow = (id) => {
        const updatedData = previewData.filter((_, index) => index !== id);
        setPreviewData(updatedData);
    };

    const fetchDataRepositoryList = async () => {
        try {
            setLoading(true);

            const obj = {
                userId: loggedInMemberDetails?.userId,
                organizationId: loggedInMemberDetails?.organizationId
            };

            const response = await axiosHelperCore({
                url: `rapport/user/getDataRepositoryList`,
                method: "POST",
                JSONData: obj
            });

            if (response?.data?.result) {
                setDataRepositoryList(response?.data?.result);
                setDataRepositoryOriginalList(response?.data?.result);
                setLoading(false);
            }
        } catch (error) {
            console.error("Error submitting content library:", error);
            showSnackbar("Something went wrong", "error");
        }
    }

    const handleSubmitPreviewData = async (newData) => {
        try {
            setLoading(true);

            const obj = {
                data: previewData?.length > 0 ? previewData : newData,
                userId: loggedInMemberDetails?.userId,
                organizationId: loggedInMemberDetails?.organizationId,
            };

            const response = await axiosHelperCore({
                url: `rapport/user/addOrUpdateDataRepository`,
                method: "POST",
                JSONData: obj
            });

            if (response?.data?.result) {
                showSnackbar("Data list is uploaded successfully", "success");
                fetchDataRepositoryList();
            }

            setPreviewData([]);
            setIsPreviewPopupOpen(false);
            setLoading(false);
        } catch (error) {
            console.error("Error submitting content library:", error);
            showSnackbar("Something went wrong", "error");
        }
    };

    const handleDeleteData = async () => {
        try {
            setLoading(true);

            const obj = {
                userId: loggedInMemberDetails?.userId,
                repoId: selectedRepo?.repoId
            };

            const response = await axiosHelperCore({
                url: `rapport/user/deleteDataRepositoryRecord`,
                method: "POST",
                JSONData: obj
            });

            if (response?.data?.success) {
                showSnackbar("Data is deleted successfully", "success");
                fetchDataRepositoryList();
            }

            setLoading(false);
        } catch (error) {
            console.error("Error submitting content library:", error);
            showSnackbar("Something went wrong", "error");
        }
    };

    const renderCellContent = (content, rowIndex) => {
        const words = content.split(" ");
        
        if (words.length > 20) {
            if (!expandedRows.has(rowIndex)) {
                const visibleWords = words.slice(0, 20).join(" ");
                return (
                    <>
                        {visibleWords}...
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                toggleRowExpansion(rowIndex);
                            }}
                            style={{ color: 'rgb(0, 148, 227)', textDecoration: 'underline', cursor: 'pointer', marginLeft: "3px"}}
                        >
                            View More
                        </a>
                    </>
                );
            } else {
                return (
                    <>
                        {content}
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                toggleRowExpansion(rowIndex);
                            }}
                            style={{ color: 'rgb(0, 148, 227)', textDecoration: 'underline', cursor: 'pointer', marginLeft: "3px" }}
                        >
                            View Less
                        </a>
                    </>
                );
            }
        }
        
        return content;
    };

    const handleShowMoreTags = (tags) => {
        setTagsToDisplay(tags);
        setIsTagsDialogOpen(true);
    };

    const handleSearchChange = (e) => {
        searchValue.current = e?.target?.value?.toLowerCase();
        seachDataPipe();

    };

    const seachDataPipe = async () => {
        let searchContext = searchValue.current;
        let filteredData = dataRepositoryOriginalList;

        if (searchContext.trim() != "") {
            filteredData = filteredData.filter(m => {
                const titleIncludes = m?.title?.toLowerCase().includes(searchContext);
                const tagsIncludes = m?.tags?.some(tag => tag.toLowerCase().includes(searchContext));
                return titleIncludes || tagsIncludes;
            });
            setDataRepositoryList(filteredData);
        }
        else {
            setDataRepositoryList(filteredData);
        }
    };

    useEffect(() => {
        if (loggedInMemberDetails?.organizationId) {
            fetchDataRepositoryList();
        }
    }, [])

    return (
        <>
            {loading && (
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                    open={true}
                >
                    <MemoRevenoidLoader />
                </Backdrop>
            )}
            { previewData.length > 0 && isPreviewPopupOpen ? (
                <PreviewDataContent
                previewData={previewData}
                setPreviewData={setPreviewData}
                handleSubmitPreviewData={handleSubmitPreviewData}
                setIsPreviewPopupOpen={setIsPreviewPopupOpen}
                />
            ) : isAddingCustomData ? (
                <AddCustomDataComponent
                    onBack={() => {setIsAddingCustomData(false); setPdfDataPreview(false);}}
                    onSave={handleSaveCustomData}
                    editData={editData}
                    pdfDataPreview={pdfDataPreview}
                />
            ) :
            (
                <>

                    <Flex justifyContent={"space-between"} alignItems="center">
                        <Flex >
                            <SearchBar placeholder="Search via title/tags" onChange={handleSearchChange} className="h-2"/>
                        </Flex>

                        <Flex justifyContent="flex-end" gap="10px">
                            <Box display={"flex"}>
                                <Button
                                    size="xs"
                                    variant="secondary"
                                    fontSize={"14px"}
                                    padding={"10px 10px"}
                                    onClick={handleAddCustomData}

                                >
                                    <div style={{ display: "flex", gap: "3px", alignItems: "center" }}>
                                        <AddIcon fill="red" width="16px" height="16px"></AddIcon>
                                        <span>Add Custom Data</span>
                                    </div>
                                </Button>
                            </Box>
                            <Box display="flex">
                                <Button
                                    size="xs"
                                    variant="secondary"
                                    fontSize="14px"
                                    padding="10px 10px"
                                    onClick={() => setIsUploadPopupOpen(true)}
                                >
                                    Upload Data
                                </Button>
                            </Box>
                        </Flex>
                    </Flex>

                    <Dialog open={isUploadPopupOpen} onClose={() => setIsUploadPopupOpen(false)}>
                        <div>
                            <DialogTitle>Upload Data</DialogTitle>
                            <DialogContent>
                                {/* <input type="file" accept=".xlsx,.csv" onChange={handleUploadFile} /> */}
                                <div style={{ fontSize: "12px" }}>Accepts .xlsx .csv .pdf format only</div>
                                <div className="file-upload-wrapper">
                                    <input accept=".xlsx,.csv,.pdf" type="file" id="fileInput" className="file-input" onChange={handleUploadFile} />
                                </div>
                                <div style={{ fontSize: "12px", marginTop: "3px", textAlign: "right", fontStyle: "italic", color: "rgb(0, 148, 227)" }}>

                                    <a style={{ textDecoration: "underline" }} href="https://docs.google.com/spreadsheets/d/1rw9rWFMR3M3hHPgHWr7C1XjtdKEEL61HzMZ_RM9yyuM/edit?gid=0#gid=0" target="_blank">Download Sample File</a>
                                </div>
                            </DialogContent>
                            <DialogActions style={{ textAlign: "right", justifyContent: "right" }}>
                                <Button size="xs" onClick={() => setIsUploadPopupOpen(false)}>Cancel</Button>
                            </DialogActions>
                        </div>
                    </Dialog>

                    <Box mt={3} zIndex={9}>
                        {dataRepositoryList.length > 0 ? (
                            <div className="flex flex-col">
                                <DataRepositoryHeader />
                                {dataRepositoryList.map((data, index) => (
                                    <Flex
                                        key={data.repoId}
                                        padding="15px"
                                        fontSize="12px"
                                        fontWeight={500}
                                        backgroundColor={index % 2 === 0 ? "#FFF" : "rgb(249, 249, 249)"}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Flex padding="0 5px" width="5%">{index + 1}</Flex>
                                        <Flex padding="0 5px" width="30%">{data.title}</Flex>
                                        <Flex padding="0 5px" width="15%">{data.createdAt}</Flex>
                                        <Flex padding="0 5px" width="40%" style={{ display: 'flex', alignItems: 'center', gap: '10px' }} >
                                            {data.tags?.slice(0, MAX_TAGS_DISPLAY).map((tag, i) => (
                                                <Chip
                                                    key={i}
                                                    label={tag}
                                                    style={{
                                                        border: "1px solid rgb(0, 148, 227)",
                                                        color: "rgb(0, 148, 227)",
                                                        backgroundColor: "white",
                                                        fontSize: "12px",
                                                        margin: "2px",
                                                    }}
                                                />
                                            ))}
                                            {data.tags?.length > MAX_TAGS_DISPLAY && (
                                                <a
                                                    href="#"
                                                    style={{ cursor: 'pointer', color: 'rgb(0, 148, 227)', textDecoration: 'underline', alignItems: "center" }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleShowMoreTags(data.tags);
                                                    }}
                                                >
                                                    Show More
                                                </a>
                                            )}
                                        </Flex>
                                        <Flex padding="0 5px" gap="10px" width="10%">
                                            <Tooltip title={`Edit ${data.title}`} placement="top" arrow>
                                                <span>
                                                    <EditButton onClick={() => handleEditRow(data)} />
                                                </span>
                                            </Tooltip>
                                            <Tooltip title={`Delete ${data.title}`} placement="top" arrow>
                                                <span>
                                                    <DeleteButton onClick={() => handleOpenPopup(data)} />
                                                </span>
                                            </Tooltip>
                                        </Flex>
                                    </Flex>
                                ))}
                            </div>
                        ) : (
                            <NoRecordFound />
                        )}
                    </Box>
                    <Dialog fullWidth open={isTagsDialogOpen} onClose={() => setIsTagsDialogOpen(false)}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0", height: "55px" }}>
                            <div>
                                <DialogTitle style={{ flex: 'none', textAlign: "center", fontSize: "20px" }}>Tags</DialogTitle>
                            </div>
                            <div style={{paddingRight:"20px"}}>
                                <a onClick={() => setIsTagsDialogOpen(false)}>
                                    <svg
                                        viewBox="0 0 24 24"
                                        width="24px"
                                        height="24px"
                                        fill="currentColor"
                                    >
                                        <path d="M18.3 5.71a1 1 0 0 0-1.41 0L12 10.59l-4.88-4.88a1 1 0 0 0-1.41 1.41L10.59 12l-4.88 4.88a1 1 0 1 0 1.41 1.41L12 13.41l4.88 4.88a1 1 0 0 0 1.41-1.41L13.41 12l4.88-4.88a1 1 0 0 0 .01-1.41z" />
                                    </svg>

                                </a>
                            </div>
                        </div>

                        <hr></hr>
                        <DialogContent>
                            {tagsToDisplay.map((tag, i) => (
                                <Chip
                                    key={i}
                                    label={tag}
                                    style={{
                                        border: "1px solid rgb(0, 148, 227)",
                                        color: "rgb(0, 148, 227)",
                                        backgroundColor: "white",
                                        fontSize: "12px",
                                        margin: "2px",
                                    }}
                                />
                            ))}
                        </DialogContent>

                    </Dialog>
                    <DeleteConfirmationPopup
                        personaName={selectedRepo?.title}
                        onDelete={handleDeleteData}
                        isOpen={isPopupOpen}
                        onClose={handleClosePopup}
                    />
                </>
            )}
        </>
    );
}

export default DataRepositoryContent;
