import React from 'react';

const SearchBar = ({ placeholder, onChange, style = {}, className = "" }) => {
  const defaultStyle = {
    border: "1px solid #0000001a",
    borderRadius: "8px",
    height: "48px"
  };

  const defaultClassName = "flex items-center w-96 h-8 rounded-lg border border-gray-200 bg-white relative";

  return (
    <div className={`${defaultClassName} ${className}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        className="ml-6 w-5 h-5 text-blue-500 absolute"
        style={{ top: '50%', transform: 'translateY(-50%)' }}
      >
        <g clipPath="url(#clip0_20008_54440)">
          <path d="M8.16504 2.525C9.24811 2.52401 10.3071 2.84427 11.2082 3.44527C12.1092 4.04627 12.8117 4.901 13.2268 5.90134C13.642 6.90168 13.7511 8.00268 13.5405 9.06505C13.3298 10.1274 12.8087 11.1034 12.0432 11.8696C11.2777 12.6358 10.3022 13.1578 9.24 13.3694C8.17782 13.5811 7.07672 13.473 6.07601 13.0587C5.07529 12.6445 4.21992 11.9427 3.61809 11.0423C3.01627 10.1418 2.69504 9.08307 2.69504 8C2.70161 6.55083 3.27986 5.16281 4.30412 4.13761C5.32838 3.11241 6.71588 2.53289 8.16504 2.525ZM8.16504 1.5C6.87946 1.5 5.62276 1.88122 4.55384 2.59545C3.48492 3.30968 2.65179 4.32484 2.15983 5.51256C1.66786 6.70028 1.53913 8.00721 1.78994 9.26809C2.04074 10.529 2.65981 11.6872 3.56885 12.5962C4.47789 13.5052 5.63608 14.1243 6.89696 14.3751C8.15783 14.6259 9.46477 14.4972 10.6525 14.0052C11.8402 13.5132 12.8554 12.6801 13.5696 11.6112C14.2838 10.5423 14.665 9.28558 14.665 8C14.665 6.27609 13.9802 4.62279 12.7612 3.40381C11.5423 2.18482 9.88895 1.5 8.16504 1.5Z" fill="#0094E3"/>
          <path d="M17.5005 16.6436L13.8155 12.9336L13.1055 13.6386L16.7905 17.3486C16.8368 17.3952 16.8918 17.4323 16.9524 17.4576C17.013 17.483 17.078 17.4961 17.1437 17.4964C17.2094 17.4966 17.2745 17.4839 17.3353 17.459C17.3961 17.434 17.4513 17.3974 17.498 17.3511C17.5446 17.3048 17.5816 17.2498 17.607 17.1892C17.6323 17.1286 17.6455 17.0636 17.6457 16.9979C17.646 16.9322 17.6333 16.8671 17.6083 16.8063C17.5834 16.7455 17.5468 16.6902 17.5005 16.6436Z" fill="#0094E3"/>
        </g>
        <defs>
          <clipPath id="clip0_20008_54440">
            <rect width="18" height="18" fill="white"/>
          </clipPath>
        </defs>
      </svg>
      <input
        type="text"
        placeholder={placeholder || "Search..."}
        className="pl-16 pr-6 py-2 outline-none w-full"
        style={{ ...defaultStyle, ...style }}
        onChange={onChange}
      />
    </div>
  );
};

export default SearchBar;
